<template>
    <v-list density="compact">
      <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForCreation" :key="index"
        @click="onContextMenuClickForCreation($event, item.id)">
        <v-list-item-title class="item-name">
          {{ $t(item.title) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </template>
  
  <script>
export default {
	props: {
		pathId: String,
		pointId: String,
	},
	data: () => ({
		itemsForCreation: [{ id: 1, title: "Finishdrawing" }],
	}),
	methods: {
		onContextMenuClickForCreation(e, id) {
			e.preventDefault();
			switch (id) {
				case 1:
					window["viewer"].finishArbitraryCreation();
					window["viewer"].clearCommandManager();
					break;
			}
		},
	},
};
</script>
  
  <style lang="scss" scoped>
  .item-name {
    font-size: 13px;;
  }

  .list-ChartContextMenu:hover {
    background-color: #0064ba !important;
  }
  </style>
  