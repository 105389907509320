import store from "@/store";
import { i18n } from "@/i18n.js";

/**
 * ロック/アンロック処理の通信を行うWebsocketのシングルトンインスタンス
 *
 * 処理の流れ
 *  1.接続
 *  2.識別情報の送信
 *  3.ロック/アンロックメッセージ送信
 *  4.ロック/アンロック結果メッセージ受信
 *  5.storeのstateを更新
 *  6.[Vueコンポーネント側]stateをwatchし、オブジェクトの状態更新等の後処理を行う
 */
class ObjectLockWebSocket {
	constructor() {
		this.socket = null;
		this.clientId = crypto.randomUUID(); // 再接続時に変わらないように初期化時に設定
		this.userId = ""; // 初期化時点ではuser情報が取得出来ていない可能性があるため接続時に設定
		this.eventName = Object.freeze({
			CONNECTED: "CONNECTED",
			IDENTIFICATION: "IDENTIFICATION",
			LOCK: "LOCK",
			LOCK_SUCCESS: "LOCK_SUCCESS",
			LOCK_FAIL: "LOCK_FAIL",
			UNLOCK: "UNLOCK",
			UNLOCK_SUCCESS: "UNLOCK_SUCCESS",
			UNLOCK_NOT_FOUND: "UNLOCK_NOT_FOUND",
			UNLOCK_FAIL: "UNLOCK_FAIL",
			FAIL: "FAIL",
		});
		this.reconnectAttempts = 0; // 再接続を試みた回数。接続時にリセットする
	}

	/** コネクションが確立されているか確認する*/
	isOpen() {
		return this.socket && this.socket.readyState === WebSocket.OPEN;
	}

	connect() {
		if (!this.isOpen()) {
			this.userId = store.state.user.id;
			this.socket = new WebSocket(
				import.meta.env.VITE_WEB_SOCKET_URL + "/wsObjectLock",
			);

			// websocket open event.
			this.socket.onopen = () => {
				console.log("WebSocket connection is open");
			};

			// websocket close event.
			this.socket.onclose = () => {
				console.log("WebSocket connection is closed");
				this.reconnect();
			};

			// websocket error event.
			this.socket.onerror = (error) => {
				console.error("WebSocket error:", error);
			};

			// websocket receive massage event.
			this.socket.onmessage = (event) => {
				const eventData = JSON.parse(event.data);

				switch (eventData.eventName) {
					/** 接続 */
					case this.eventName.CONNECTED:
						this.reconnectAttempts = 0;
						this.sendEventIdentification();
						break;
					/** ロック関連 */
					case this.eventName.LOCK_SUCCESS:
						store.dispatch("lock/attemptLock", eventData.jsonName);
						break;
					case this.eventName.LOCK_FAIL:
						const lockUser = eventData?.data?.locked_name;
						if (lockUser) {
							store.commit("set_snackbar", {
								text: i18n.global.t("EXCLUSIVE_NOTICE", { lockUser }),
								color: "rgba(153, 0, 0, 0.72)",
							});
						} else {
							store.commit("set_snackbar", {
								text: i18n.global.t("LOCK_FAILED"),
								color: "rgba(153, 0, 0, 0.72)",
							});
						}
						break;
					/** アンロック関連 */
					case this.eventName.UNLOCK_SUCCESS:
						store.dispatch("lock/releaseLock");
						break;
					case this.eventName.UNLOCK_NOT_FOUND:
					case this.eventName.UNLOCK_FAIL:
						// 上記はアンロック対象のロック情報が無いときに返却される
						// ユーザーが期待した通りのアンロックという状態には既になっているということなのでエラーメッセージは表示しない
						break;
					/** ロック/アンロック共通 */
					case this.eventName.FAIL:
						store.commit("set_snackbar", {
							text: i18n.global.t("LOCK_OR_UNLOCK_ERROR"),
							color: "rgba(153, 0, 0, 0.72)",
						});
						break;
				}
			};
		}
	}

	reconnect() {
		// 5秒に1回、最大3回再接続を行う
		const MAX_RECONNECT_ATTEMPTS = 3;
		if (this.reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
			setTimeout(() => {
				if (!this.isOpen()) {
					console.log(`Reconnecting attempt ${this.reconnectAttempts + 1}`);
					this.reconnectAttempts++; // 再接続カウントをインクリメント
					this.connect();
				}
			}, 5000);
		} else {
			store.commit("set_snackbar", {
				text: i18n.global.t("LOST_OBJECT_EDIT_WEBSOCKET_CONNECTION"),
				color: "rgba(153, 0, 0, 0.72)",
			});
		}
	}

	send(data) {
		this.socket.send(JSON.stringify(data));
	}

	/** 識別情報を送信 */
	sendEventIdentification = () => {
		if (this.isOpen()) {
			const sendObj = {
				eventName: this.eventName.IDENTIFICATION,
				clientId: this.clientId,
				userId: this.userId,
			};
			this.send(sendObj);
		}
	};

	/** ロック */
	sendLockEvent = (jsonName) => {
		// 接続があればリクエストする
		if (this.isOpen()) {
			const sendObj = {
				eventName: this.eventName.LOCK,
				clientId: this.clientId,
				jsonName,
			};
			this.send(sendObj);
		}
	};

	/** アンロック */
	sendUnLockEvent = () => {
		// 接続があればリクエストする
		if (this.isOpen()) {
			const sendObj = {
				eventName: this.eventName.UNLOCK,
				clientId: this.clientId,
			};
			this.send(sendObj);
		}
	};
}
/** シングルトンインスタンスを生成してエクスポートする */
const objectLockWebSocket = new ObjectLockWebSocket();
export default objectLockWebSocket;
