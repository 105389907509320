<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title> {{ $t("EXPORT_AS") }} </v-card-title>
  <v-card-text>
      <v-card-title class="justify-center text-center" style="word-break:break-word;white-space: break-spaces;">
          {{$t('ExportRoad', {name: name})}}<br />
          {{$t('SelectOutputFormat')}}
      </v-card-title>
      <v-radio-group v-model="active">
        <v-radio name="active" :label="$t('LandXML')" :value=0></v-radio>
        <v-radio name="active" :label="$t('Registertodashboard')" :value=1></v-radio>       
      </v-radio-group>
      <v-row class="ma-0">
        <v-col>
          <v-card-actions class="justify-end">
            <v-btn 
              variant="flat"
              @click="closeDialog" 
              color="red"
            >
              {{ $t("CANCEL") }}
            </v-btn>
            <v-btn variant="flat" @click="exportXmlOrData()" color="primary">
              {{ $t("EXPORT_AS") }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { LandXML } from "earthbrain-viewer";
import * as cesiumCommon from "@/utils/cesium-common";
import { mapMutations } from "vuex";

export default {
	name: "ExportDialog",
	props: {
		id: String,
		type: Number,
		pointId: String,
		name: String,
	},
	data: () => ({
		active: 0,
	}),
	methods: {
		...mapMutations(["set_snackbar"]),
		resetProperty() {
			this.active = 0;
		},
		closeDialog() {
			this.$emit("close", false);
		},
		async toggleVisibility(obj) {
			obj.isShow = !obj.isShow;
			// if (obj.edit && !obj.isShow) {
			//   this.enableControlPointDragging(index);
			// }
			const activePointCloudId =
				this.$store.getters.activePointCloud?.getAssetIdNum();
			if (obj.type === 0) {
				const associatePointCloudId = JSON.parse(
					obj.json,
				).associatePointCloudId;
				let isLoadedWithoutPointCloud = false;
				if (associatePointCloudId) {
					const pointCloud = this.$store.getters.visiblePointClouds.find(
						(p) => Number.parseInt(p.asset_id) === associatePointCloudId,
					);
					isLoadedWithoutPointCloud = !pointCloud;
				}
				await cesiumCommon.toggleRoadVisibility(
					obj,
					activePointCloudId,
					isLoadedWithoutPointCloud,
				);
			} else if (obj.type === 1) {
				await cesiumCommon.toggleFlatGroundVisibility(obj, activePointCloudId);
			} else if (obj.type === 7) {
				const associatePointCloudId = JSON.parse(
					obj.json,
				).associatePointCloudId;
				let isLoadedWithoutPointCloud = false;
				if (associatePointCloudId) {
					const pointCloud = this.$store.getters.visiblePointClouds.find(
						(p) => Number.parseInt(p.asset_id) === associatePointCloudId,
					);
					isLoadedWithoutPointCloud = !pointCloud;
				}
				await cesiumCommon.setTrenchVisibility(
					obj,
					activePointCloudId,
					isLoadedWithoutPointCloud,
				);
			}
		},
		exportXmlOrData() {
			if (this.active === 0) {
				this.exportLandXML();
			} else {
				this.uploadLandXML();
			}
		},
		async exportLandXML() {
			const obj = this.$store.state.objects.find((item) => item.id === this.id);
			const site = this.$store.state.site;
			if (!obj) return;
			let geometry;
			const isLoaded = obj.isLoaded;
			// viewerに読み込まれていなければ読み込む（一時的に表示中にする）
			if (!isLoaded) await this.toggleVisibility(obj);
			if (obj.type === 0) {
				geometry = window["viewer"].getRoadMeshGeometry(obj.id);
			} else if (obj.type === 1) {
				geometry = window["viewer"].getFlatGroundMeshGeometry(obj.id);
			} else if (obj.type === 7) {
				geometry = window["viewer"].getTrenchMeshGeometry(obj.id);
			}
			// 表示中にしたものを非表示に戻す
			if (!isLoaded) await this.toggleVisibility(obj);

			if (geometry) {
				const points = [];
				for (let i = 0; i < geometry.vertices.length; i += 3) {
					points.push({
						x: geometry.vertices[i],
						y: geometry.vertices[i + 1],
						z: geometry.vertices[i + 2],
					});
				}
				const landxmlPointsBuffer = [];
				for (const point of points) {
					landxmlPointsBuffer.push(point.y, point.x, point.z); //for landxml, x and y should be flipped
				}
				LandXML.exportMesh(
					landxmlPointsBuffer,
					geometry.indices,
					site.site_name,
					this.createFileName(new Date(), obj.name, site.site_name),
				);
			} else {
				console.error("Please check if the entity is created");
			}
			this.closeDialog();
		},
		async uploadLandXML() {
			const obj = this.$store.state.objects.find((item) => item.id === this.id);
			const site = this.$store.state.site;
			const isLoaded = obj.isLoaded;
			// viewerに読み込まれていなければ読み込む（一時的に表示中にする）
			if (!isLoaded) this.toggleVisibility(obj);
			if (!obj) return;
			let geometry;
			if (obj.type === 0) {
				geometry = window["viewer"].getRoadMeshGeometry(obj.id);
			} else if (obj.type === 1) {
				geometry = window["viewer"].getFlatGroundMeshGeometry(obj.id);
			} else if (obj.type === 7) {
				geometry = window["viewer"].getTrenchMeshGeometry(obj.id);
			}
			// 表示中にしたものを非表示に戻す
			if (!isLoaded) this.toggleVisibility(obj);

			if (geometry) {
				const points = [];
				for (let i = 0; i < geometry.vertices.length; i += 3) {
					points.push({
						x: geometry.vertices[i],
						y: geometry.vertices[i + 1],
						z: geometry.vertices[i + 2],
					});
				}
				const landxmlPointsBuffer = [];
				for (const point of points) {
					landxmlPointsBuffer.push(point.y, point.x, point.z); //for landxml, x and y should be flipped
				}
				// Mechanism to return xml without downloading if the 4th argument is omitted.
				const xml = LandXML.exportMesh(
					landxmlPointsBuffer,
					geometry.indices,
					site.site_name,
				);

				this.$store.dispatch("updateIsLoading", true);
				await axios
					.post(`${import.meta.env.VITE_API_BASE}/dashboard/xml`, {
						xmlData: xml,
						xmlName: this.createFileName(new Date(), obj.name, site.site_name),
						siteId: this.$route.query.siteId,
					})
					.then(() => {
						this.set_snackbar({
							text: `${this.$t("Registertodashboard")} ${this.$t("successful")}`,
							color: "rgba(0, 153, 0, 0.72)",
						});
					})
					.catch((e) => {
						this.set_snackbar({
							text: `${this.$t("Registertodashboard")} ${this.$t("failed")}`,
							color: "rgba(153, 0, 0, 0.72)",
						});
						console.log(e.message);
					})
					.finally(() => {
						this.$store.dispatch("updateIsLoading", false);
					});
			} else {
				console.error("Please check if the entity is created");
			}
			this.closeDialog();
		},
		createFileName(date, objName, siteName) {
			return (
				[
					date.getFullYear(),
					this.padSingleDigit(date.getMonth() + 1),
					this.padSingleDigit(date.getDate()),
				].join("-") +
				" " +
				[
					this.padSingleDigit(date.getHours()),
					this.padSingleDigit(date.getMinutes()),
					this.padSingleDigit(date.getSeconds()),
				].join("_") +
				`_${siteName}_${objName}`
			);
		},
		padSingleDigit(num) {
			return num.toString().padStart(2, "0");
		},
	},
};
</script>