<template>
  <v-card>
    <v-card-title class="d-flex align-center justify-space-between">
      {{ $t("SELECT_DATA_TYPE") }}
      <v-btn icon variant="flat" @click="close()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon size="34"> icon:Mountain </v-icon>
                </v-col>
                <v-col cols="10" @click="openTerrainDataDialog('pointcloud')">
                  <v-card-title class="card-btn-title"> {{ $t("POINT_CLOUD") }} </v-card-title>
                  <v-card-text>LASer(.las), CSV(.txt, .csv)</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon"> <v-icon size="34"> icon:Cubes </v-icon></v-col>
                <v-col cols="10" @click="openTerrainDataDialog('design')">
                  <v-card-title class="card-btn-title"> {{ $t("DESIGN") }} </v-card-title>
                  <v-card-text>LandXML(.xml), DXF(.dxf)</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon size="34"> icon:Cubes </v-icon>
                </v-col>
                <v-col cols="10" @click="openTerrainDataDialog('lineworks')">
                  <v-card-title class="card-btn-title"> {{ $t("LINEWORKS") }} </v-card-title>
                  <!-- TODO: 多言語化 -->
                  <v-card-text>DXF(.dxf)</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon size="34"> icon:Export</v-icon>
                </v-col>
                <v-col cols="10" @click="openExportFromDashboardDialog">
                  <v-card-title class="card-btn-title"> {{ $t("LOCALIZATION_FILE") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

export default {
	name: "SelectType",
	props: {
		id: String,
	},
	mounted: () => {},
	data: () => ({
		showTerrainDataDialog: false,
	}),
	methods: {
		...mapMutations(["set_snackbar"]),
		...mapActions(["get_point_cloud_list", "get_design_list"]),
		close() {
			this.$emit("close");
		},
		openExportFromDashboardDialog() {
			const dummyId = 1;
			const event = new CustomEvent("openPropertyDialog", {
				detail: { id: dummyId, type: 8 },
			});
			window.dispatchEvent(event);
			this.close();
		},
		openTerrainDataDialog(type) {
			switch (type) {
				case "pointcloud": {
					const allowedFileTypes = ".txt,.las,.csv";
					const axisShow = true;
					this.$emit("open-terrain-data", type, allowedFileTypes, axisShow);
					break;
				}
				case "design": {
					const allowedFileTypes = ".xml,.dxf";
					const axisShow = false;
					this.$emit("open-terrain-data", type, allowedFileTypes, axisShow);
					break;
				}
				case "lineworks": {
					const allowedFileTypes = ".dxf";
					const axisShow = false;
					this.$emit("open-terrain-data", type, allowedFileTypes, axisShow);
					break;
				}
			}
		},
	},
};
</script>

<style scoped>
.card-btn {
  display: flex;
  align-items: center;
  height: 110px;
}

.card-btn:hover {
  background-color: #2e2e2e;
}

.card-btn-title {
  white-space: normal
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.type-select-btn {
  width: 90%;
}
</style>
