<template>
  <v-card>
      <div id="lineSectionView" tile class="pa-0 chart-board"
      ref="lineSectionView"></div>
  </v-card>
</template>

<script>
import "./style.css";

export default {
	name: "LineSectionViewDialog",
	props: {
		id: String,
	},

	mounted() {
		this.$nextTick(function () {
			this.update();
			this.onScreenResize();
		});
	},
	data: () => ({}),
	methods: {
		onScreenResize() {
			window.addEventListener("resize", () => {
				this.update();
			});
		},
		update() {
			const canvas =
				window["viewer"] &&
				window["viewer"].getCanvasForView("lineSectionView");
			const element = this.$refs.lineSectionView;
			if (canvas && element) {
				for (const node of element.children) {
					element.removeChild(node);
				}
				element.appendChild(canvas);
				canvas.height = element.getBoundingClientRect().height;
				canvas.width = element.getBoundingClientRect().width;
				window["viewer"].resize2dView(
					{ width: canvas.width, height: canvas.height },
					"lineSectionView",
				);
			}
		},
	},
};
</script>


<style scoped>
.line-section-container{
  width: 100%;
  height: 240px;
}
</style>