<template>
  <div class="vld-parent">
    <loading 
      :active="parentLoading"
      :is-full-page="true"
    />
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
export default {
	props: ["parentLoading"],
	components: {
		Loading,
	},
};
</script>