export default class Measurement {
	constructor(measurementId, measurementName, startPoints, endPoints) {
		// APIから取得するデータ
		this.measurementId = measurementId;
		this.measurementName = measurementName;
		this.startPoints = startPoints;
		this.endPoints = endPoints;

		// フロント側で設定するデータ
		this.assetId = ""; // 表示中のアセットID
		this.isLinearVisible = false; // 線形の表示ステータス
		this.isLinearLoaded = false; // 線形が読み込み済みかを保持する
		this.isDistanceVisible = false; // 距離の表示ステータス
		this.isDistanceLoaded = false; // 距離が読み込み済みかを保持する
		this.isCrossSectionVisible = false; // 断面の表示ステータス
		this.isCrossSectionLoaded = false; // 断面が読み込み済みかを保持する
	}

	// getter
	get measurementId() {
		return this._measurement_id;
	}

	get measurementName() {
		return this._measurement_name;
	}

	get startPoints() {
		return this._start_points;
	}

	get endPoints() {
		return this._end_points;
	}

	get assetId() {
		return this._asset_id;
	}

	get isLinearVisible() {
		return this._is_linear_visible;
	}

	get isLinearLoaded() {
		return this._is_linear_loaded;
	}

	get isDistanceVisible() {
		return this._is_distance_visible;
	}

	get isDistanceLoaded() {
		return this._is_distance_loaded;
	}

	get isCrossSectionVisible() {
		return this._is_cross_section_visible;
	}

	get isCrossSectionLoaded() {
		return this._is_cross_section_loaded;
	}

	// setter
	set measurementId(measurementId) {
		this._measurement_id = measurementId;
	}

	set measurementName(measurementName) {
		this._measurement_name = measurementName;
	}

	set startPoints(startPoints) {
		this._start_points = JSON.parse(startPoints).map(Number);
	}

	set endPoints(endPoints) {
		this._end_points = JSON.parse(endPoints).map(Number);
	}

	set assetId(assetId) {
		this._asset_id = assetId;
	}

	set isLinearVisible(isLinearVisible) {
		this._is_linear_visible = isLinearVisible;
	}

	set isLinearLoaded(isLinearLoaded) {
		this._is_linear_loaded = isLinearLoaded;
	}

	set isDistanceVisible(isDistanceVisible) {
		this._is_distance_visible = isDistanceVisible;
	}

	set isDistanceLoaded(isDistanceLoaded) {
		this._is_distance_loaded = isDistanceLoaded;
	}

	set isCrossSectionVisible(isCrossSectionVisible) {
		this._is_cross_section_visible = isCrossSectionVisible;
	}

	set isCrossSectionLoaded(isCrossSectionLoaded) {
		this._is_cross_section_loaded = isCrossSectionLoaded;
	}

	//functions
	update(data) {
		this.measurementName = data.two_points_picker_name;
		this.startPoints = data.start_info;
		this.endPoints = data.end_info;
	}

	reset() {
		this.assetId = "";
		this.isLinearVisible = false;
		this.isLinearLoaded = false;
		this.isDistanceVisible = false;
		this.isDistanceLoaded = false;
		this.isCrossSectionVisible = false;
		this.isCrossSectionLoaded = false;
	}
}
