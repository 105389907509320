<template>
  <v-btn
    min-width="0"
    height="auto"
    class="pa-0"
    variant="plain"
    @click.stop="$emit('click')"
  >
    <v-icon :size="size"> mdi-close </v-icon>
  </v-btn>
</template>

<script>
export default {
	name: "CloseButton",
	props: {
		size: String,
	},
};
</script>