<template>
  <list-panel />
</template>

<script>
import ListPanel from "../components/List/ListPanel.vue";
import axios from "axios";

export default {
	name: "ListPage",

	components: {
		ListPanel,
	},
	data: () => ({}),
	destoroyed() {},
	methods: {
		async showNotificationPopUp() {
			try {
				const [userLicenseRes, corporationLicenseRes] = await Promise.all([
					axios.get(`${import.meta.env.VITE_API_BASE}/license/user`),
					axios.get(`${import.meta.env.VITE_API_BASE}/license/corporation`),
				]);
				if (!userLicenseRes.data && corporationLicenseRes.data) {
					// お知らせダイアログを表示
					const licenseRegistrationUrl = import.meta.env
						.VITE_LICENSE_REGISTRATION_URL;
					const event = new CustomEvent("openNotificationPopup", {
						detail: {
							title: this.$t("NOTICE"),
							message: this.$t("LICENSE_MIGRATION_MESSAGE", {
								url: licenseRegistrationUrl,
							}),
						},
					});
					window.dispatchEvent(event);
				}
			} catch (e) {
				console.error(e);
				this.$store.commit("set_snackbar", {
					text: `${this.$t("LICENSE_CHECK")} ${this.$t("failed")}`,
					color: "rgba(153, 0, 0, 0.72)",
				});
			}
		},
	},
	mounted() {
		this.showNotificationPopUp();
	},
};
</script>

<style scoped></style>
