export default class LineWorks {
	constructor(
		lineId,
		lineName,
		path,
		processingStatus,
		createdAt,
		siteId,
		lineType,
	) {
		this._lineId = lineId;
		this._lineName = lineName;
		this._isVisible = false;
		this._isLoaded = false;
		this._isLoadedInViewer = false;
		this._isCrossSectionVisible = false;
		this._isCrossSectionLoaded = false;
		this._createdAt = new Date(createdAt);
		this._lineType = lineType;
		this._linePath = path;
		this._processingStatus = processingStatus;
		this._siteId = siteId;
	}

	// getter
	get lineId() {
		return this._lineId;
	}

	get lineName() {
		return this._lineName;
	}

	get isVisible() {
		return this._isVisible;
	}

	get isLoaded() {
		return this._isLoaded;
	}

	get isLoadedInViewer() {
		return this._isLoadedInViewer;
	}

	get isCrossSectionVisible() {
		return this._isCrossSectionVisible;
	}

	get isCrossSectionLoaded() {
		return this._isCrossSectionLoaded;
	}

	get viewerLineId() {
		return this._viewerLineId;
	}

	get crossSectionSetting() {
		return this._crossSectionSetting;
	}

	get createdAt() {
		return this._createdAt;
	}

	get siteId() {
		return this._siteId;
	}

	// setter
	set lineId(lineId) {
		this._lineId = lineId;
	}

	set lineName(lineName) {
		this._lineName = lineName;
	}

	set isVisible(isVisible) {
		this._isVisible = isVisible;
	}

	set isLoaded(isLoaded) {
		this._isLoaded = isLoaded;
	}

	set isCrossSectionVisible(isCrossSectionVisible) {
		this._isCrossSectionVisible = isCrossSectionVisible;
	}

	set isCrossSectionLoaded(isCrossSectionLoaded) {
		this._isCrossSectionLoaded = isCrossSectionLoaded;
	}

	set viewerLineId(viewerLineId) {
		this._viewerLineId = viewerLineId;
	}

	set crossSectionSetting(crossSectionSetting) {
		this._crossSectionSetting = crossSectionSetting;
	}

	set createdAt(createdAt) {
		this._createdAt = new Date(createdAt);
	}

	set isLoadedInViewer(isLoadedInviewer) {
		this._isLoadedInViewer = isLoadedInviewer;
	}

	get lineType() {
		return this._lineType;
	}

	get processingStatus() {
		return this._processingStatus;
	}

	update({ lineName, processingStatus }) {
		this._lineName = lineName;
		this._processingStatus = processingStatus;
	}
}
