<template>
  <v-navigation-drawer
    permanent
    mobile-breakpoint="0"
    color="#17171a"
    style="width: 50vw; border-top: 1px solid #434348"
  >
    <v-card color="#17171a" flat height="100%">
      <v-card-title class="pl-4 pt-6">
        <h1>{{$t("PROJECT")}}</h1>
      </v-card-title>
      <v-card-text style="height: calc(100% - 72px)">
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="word"
              variant="solo"
              density="compact"
              :label="$t('list_search_title')"
              type="text"
              hide-details
              class="pt-10 pb-0"
              style="width: 70%; height: 30px;"
              append-icon="mdi-magnify"
              @click:append="onSearchChange"
              @keyup.enter="getSitesEnter($event)"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="limit"
              :items="numbers"
              :label="$t('number')"
              hide-details
              class="pt-10 pb-0"
            ></v-select>
          </v-col>
        </v-row>
        <v-table class="pr-3 py-3 my-3 project-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="width: 80%">{{$t("JOBSITE_NAME")}}</th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="item in sites"
                :key="item.name"
                @mouseenter="on_mouseenter(item)"
                @mouseleave="on_mouseleave(item)"
                @click="on_click(item)"
              >
                <td>{{ item.siteName }}</td>
                <td class="text-right pa-0">
                  <v-btn
                    color="primary"
                    height="100%"
                    @click="gotoProject(item)"
                  >
                    {{$t("OPEN")}}
                    <v-icon size="small"> icon:Arrow-Right </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
        <v-pagination :length="pageCount" v-model="page" @update:model-value="getSites">
        </v-pagination>
      </v-card-text>
    </v-card>
    <v-dialog v-model="isFileDialogOpen" width="600px" v-if="isFileDialogOpen" persistent>
      <local-file-upload v-bind:project ="{ site_id: this.siteId }" @close="closeFileUploadDialog"/>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";

import LocalFileUpload from "../AddData/LocalFileUpload.vue";
export default {
	name: "ListPanel",

	components: { LocalFileUpload },
	data: () => ({
		sites: [],
		numbers: [5, 10, 15, 20],
		word: "",
		pageCount: 1,
		page: 1,
		limit: 10,
		projects: [
			{
				name: "test",
				siteId: "test",
			},
		],
		isFileDialogOpen: false,
		siteId: undefined,
	}),
	async mounted() {
		this.getSites();
	},
	unmounted() {
		console.log("Destroyed List");
	},
	watch: {
		sites(newValue, oldValue) {
			// pinを削除
			this.removePins(oldValue);
			// pinを追加
			this.addPins(newValue);
		},
		limit() {
			// 1ページ目に戻して検索実行
			this.getSites(1);
		},
	},
	methods: {
		on_mouseenter(item) {
			window["viewer"].updatePin(item.pinId, {
				color: "#eaaa00",
				highlight: true,
			});
		},
		on_mouseleave(item) {
			window["viewer"].updatePin(item.pinId, {
				color: "#ffffff",
				highlight: false,
			});
		},
		on_click(item) {
			window["viewer"].focusPin(item.pinId);
		},
		getSitesEnter(event) {
			// フォーカスアウトする
			event.target.blur();
			// 検索実行
			this.onSearchChange();
		},
		async gotoProject(site) {
			console.log("open the site");
			this.siteId = site.siteId;

			// Google Analyticsに連携
			window["viewer"].focusPin(site.pinId);
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "sc_jobsite_open",
				sc_site_id: this.siteId,
			});

			try {
				const response = await axios.get(
					`${import.meta.env.VITE_API_BASE}/sites/${this.siteId}`,
					{
						auth: this.$store.state.authObject,
					},
				);
				if (
					!response.data.epsg &&
					!response.data.epsg_v &&
					!response.data.rotation
				) {
					this.isFileDialogOpen = true;
				} else {
					this.$router.push(`/?siteId=${this.siteId}`);
				}
			} catch (error) {
				if (error.message.indexOf("504") > 0) {
					window.location.href = `${import.meta.env.VITE_API_BASE}/login`;
				}
			}

			//remove all the pins
			this.removePins(this.sites);
		},
		addPins(sites) {
			for (const site of sites) {
				site.pinId = window["viewer"].addPin(
					Number(site.longitude),
					Number(site.latitude),
					"#ffffff",
				);
			}
		},
		removePins(sites) {
			for (const site of sites) {
				window["viewer"].removePin(site.pinId);
			}
		},
		onSearchChange() {
			// 1ページ目に戻して検索実行
			this.getSites(1);
		},
		async setPageCount() {
			const params = {};

			if (this.word) {
				params.siteName = this.word;
			}

			try {
				const response = await axios.get(
					`${import.meta.env.VITE_API_BASE}/sites/count`,
					{
						auth: this.$store.state.authObject,
						params,
					},
				);
				const siteCount = response.data.siteCount;
				// ページ数
				return siteCount % this.limit === 0
					? Math.floor(siteCount / this.limit)
					: Math.floor(siteCount / this.limit) + 1;
			} catch (e) {
				// リクエストに失敗したらページ数を初期値に戻す
				console.error(e.message);
				throw e;
			}
		},
		async getSites(pageNumber) {
			// 引数がある場合は指定されたページのデータを取得
			const page = pageNumber ? pageNumber : this.page;
			const params = {
				offset: page * this.limit - this.limit + 1,
				limit: this.limit,
			};

			if (this.word) {
				params.siteName = this.word;
			}

			try {
				this.$store.dispatch("updateIsLoading", true);
				const pageCount = await this.setPageCount();
				const response = await axios.get(
					`${import.meta.env.VITE_API_BASE}/sites`,
					{
						auth: this.$store.state.authObject,
						params,
					},
				);
				this.sites = response.data.sites;
				// 現場情報取得後にページ数と現在のページ番号を画面に反映
				this.pageCount = pageCount;
				this.page = page;
			} catch (e) {
				console.error(e.message);
			} finally {
				this.$store.dispatch("updateIsLoading", false);
			}
		},
		//here we are sending the uploaded data to the server
		async closeFileUploadDialog(uploadedData) {
			this.isFileDialogOpen = false;
			if (uploadedData) {
				this.$router.push(`/?siteId=${this.siteId}`);
			}
		},
	},
};
</script>

<style>
.v-navigation-drawer__content {
  overflow-y: hidden !important;
}

.project-table {
  max-height: calc(100% - 140px - 48px);
  overflow-y: auto;
}
</style>
