<template>
  <v-card class="px-4 pt-2 pb-4" color="rgba(41, 41, 47, 0.8)">
    <v-card-title> {{ $t("EDIT_LINEWORK_INFO") }}</v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-row class="justify-center">
          <line-works-cross-section-input :crossSectionData="crossSectionData">
          </line-works-cross-section-input>
        </v-row>
        <v-row>
          <v-card-actions class="full-width justify-end mt-2">
            <v-btn variant="flat" @click="closeDialog()" color="red">
              {{ $t("CANCEL") }}
            </v-btn>
            <v-btn variant="flat" @click="updateCrossSection()" color="primary" :disabled="!isValid" >
              {{ $t("EDIT") }}
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import rules from "@/utils/validation-rules.js";
import LineWorksCrossSectionInput from "./LineWorksCrossSectionInput.vue";
import { defaultLineWorksCrossSection } from "./default";

export default {
	name: "LineWorksCrossSectionEditDialog",
	components: { LineWorksCrossSectionInput },
	props: {
		lineWorksId: String,
		crossSectionData: Object,
	},
	mounted() {
		this.oldCrossSectionData = { ...this.crossSectionData };
	},
	data: () => ({
		isValid: false,
		oldCrossSectionData: undefined,
		rules: {
			...rules,
			required: (value) => !!value || "*",
			validName: (v) => !/[~@#$^*()+=[\]{}|\\,?:<>!'"/;&₹€`%]/.test(v),
		},
	}),
	methods: {
		...mapActions("lineWorksCrossSection", ["saveCrossSectionOfLineWorks"]),
		...mapGetters(["linearList"]),
		resetProperty() {
			this.crossSectionData = defaultLineWorksCrossSection();
			this.isValid = false;
		},
		closeDialog() {
			this.crossSectionData.leftWidth = this.oldCrossSectionData.leftWidth;
			this.crossSectionData.rightWidth = this.oldCrossSectionData.rightWidth;
			this.crossSectionData.interpolationPitch =
				this.oldCrossSectionData.interpolationPitch;
			window["viewer"].cancelArbitraryCreation();
			this.$emit("close", false);
		},
		async updateCrossSection() {
			try {
				// ローディング開始
				await this.$store.dispatch("executeWithSpinner", async () => {
					try {
						await window["viewer"].generateLineWorksCrossSection(
							this.lineWorksId,
							this.crossSectionData,
						);
						const lineWorks = this.linearList().find(
							(linearWork) => linearWork.viewerLineId === this.lineWorksId,
						);
						if (lineWorks) {
							lineWorks.isCrossSectionVisible = true;
						}
						await this.saveCrossSectionOfLineWorks({
							crossSectionSetting: this.crossSectionData,
						});
					} catch (e) {
						// Todo display appropriate message
						console.error(e);
					}
				});
			} finally {
				this.$emit("close", false);
			}
		},
	},
};
</script>

<style scoped>
.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: 2em;
}

.pt-2 {
  padding-top: 2em;
}

.full-width {
  width: 100%;
}
</style>