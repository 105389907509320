import axios from "axios";
import LineWorks from "../../models/LineWorks";

const state = {
	arbitraryLineList: [],
};

const getters = {
	arbitraryLineList: (state) => state.arbitraryLineList,
};

const mutations = {
	setArbitraryLineList(state, arbitraryLineList) {
		state.arbitraryLineList = arbitraryLineList;
	},
	addArbitraryLine(state, arbitraryLine) {
		state.arbitraryLineList.push(arbitraryLine);
	},
	updateArbitraryLine(state, simaLine) {
		const index = state.simaLineList.findIndex(
			(item) => item.lineId === simaLine.lineId,
		);
		if (index !== -1) {
			state.simaLineList[index] = simaLine;
			// リアクティブにするためにstateを新規配列で上書き
			state.simaLineList = [...state.simaLineList];
		}
	},
};

const actions = {
	async getArbitraryLineList({ state, commit }, site_id) {
		const arbitraryLineList = [];
		const res = await axios.get(
			`${import.meta.env.VITE_API_BASE}/arbitraryLinear/${site_id}`,
			{
				auth: this.state.authObject,
			},
		);
		for (const arbitraryLine of res.data) {
			const currentArbitraryLine = state.arbitraryLineList.find(
				(entity) => entity.lineId === arbitraryLine.arbitrary_linear_id,
			);
			if (currentArbitraryLine) {
				currentArbitraryLine.update({
					lineName: arbitraryLine.arbitrary_linear_name,
					processingStatus: "complete",
				});
				arbitraryLineList.push(currentArbitraryLine);
			} else {
				const newArbitraryLine = new LineWorks(
					arbitraryLine.arbitrary_linear_id,
					arbitraryLine.arbitrary_linear_name,
					"",
					"complete",
					arbitraryLine.create_at,
					arbitraryLine.site_id,
					"arbitrary",
				);
				newArbitraryLine.isLoaded = false;
				arbitraryLineList.push(newArbitraryLine);
			}
		}

		try {
			const response = await axios.get(
				`${import.meta.env.VITE_API_BASE}/crossSection/arbitraryLinear/${site_id}`,
				{
					auth: this.state.authObject,
				},
			);
			const arbitraryLineCrossSections = response.data.filter(
				(l) => l.related_object_type === 3,
			);
			for (const line of arbitraryLineList) {
				for (const crossSection of arbitraryLineCrossSections) {
					if (line.lineId === crossSection.related_object_id) {
						line.crossSectionSetting = {
							id: crossSection.cross_section_id,
							name: crossSection.name,
							leftWidth: crossSection.left_side_width,
							rightWidth: crossSection.right_side_width,
							interpolationPitch: crossSection.station_interval,
							samplingSize: crossSection.sampling_size,
						};
						line.isCrossSectionLoaded = true;
						break;
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
		commit("setArbitraryLineList", arbitraryLineList);
	},

	async addArbitraryLine({ commit }, { lineInfo, crossSectionSetting }) {
		try {
			const arbitraryLinearRes = await axios.post(
				`${import.meta.env.VITE_API_BASE}/arbitraryLinear/${this.state.site.site_uuid}`,
				{
					siteId: this.state.site.site_uuid,
					name: lineInfo.label,
					data: { version: "1.0", lineInfo },
				},
			);

			const crossSectionRes = await axios.post(
				`${import.meta.env.VITE_API_BASE}/crossSection/arbitrarylinear/${this.state.site.site_uuid}/${arbitraryLinearRes.data.arbitrary_linear_id}`,
				{
					name: lineInfo.label,
					leftSideWidth: crossSectionSetting.leftWidth,
					rightSideWidth: crossSectionSetting.rightWidth,
					stationInterval: crossSectionSetting.interpolationPitch,
					samplingSize: crossSectionSetting.samplingSize,
				},
			);
			const newCrossSectionSetting = {
				id: crossSectionRes.data.cross_section_id,
				name: crossSectionRes.data.name,
				leftWidth: crossSectionRes.data.left_side_width,
				rightWidth: crossSectionRes.data.right_side_width,
				interpolationPitch: crossSectionRes.data.station_interval,
				samplingSize: crossSectionRes.data.sampling_size,
			};

			const newArbitraryLine = new LineWorks(
				arbitraryLinearRes.data.arbitrary_linear_id,
				arbitraryLinearRes.data.arbitrary_linear_name,
				"",
				"complete",
				arbitraryLinearRes.data.create_at,
				arbitraryLinearRes.data.site_id,
				"arbitrary",
			);
			newArbitraryLine.isLoaded = true;
			newArbitraryLine.viewerLineId = lineInfo.viewerLineId;
			newArbitraryLine.isVisible = true;
			newArbitraryLine.isCrossSectionLoaded = true;
			newArbitraryLine.isCrossSectionVisible = true;
			newArbitraryLine.crossSectionSetting = newCrossSectionSetting;
			newArbitraryLine.isLoadedInViewer = true;
			window["viewer"].toggleArbitraryLineVisibility(
				newArbitraryLine.viewerLineId,
				newArbitraryLine.isVisible,
			);
			await window["viewer"].generateLineWorksCrossSection(
				newArbitraryLine.viewerLineId,
				newCrossSectionSetting,
			);
			commit("addArbitraryLine", newArbitraryLine);
		} catch (err) {
			console.warn(err);
			throw err;
		}
	},

	async addCrossSectionSettingForArbitraryLine(
		{ commit },
		{ arbitraryLine, crossSectionSetting },
	) {
		const crossSectionRes = await axios.post(
			`${import.meta.env.VITE_API_BASE}/crossSection/arbitrarylinear/${this.state.site.site_uuid}/${arbitraryLine.lineId}`,
			{
				name: arbitraryLine.lineName,
				leftSideWidth: crossSectionSetting.leftWidth,
				rightSideWidth: crossSectionSetting.rightWidth,
				stationInterval: crossSectionSetting.interpolationPitch,
				samplingSize: crossSectionSetting.samplingSize,
			},
		);
		const newCrossSectionSetting = {
			id: crossSectionRes.data.cross_section_id,
			name: crossSectionRes.data.name,
			leftWidth: crossSectionRes.data.left_side_width,
			rightWidth: crossSectionRes.data.right_side_width,
			interpolationPitch: crossSectionRes.data.station_interval,
			samplingSize: crossSectionRes.data.sampling_size,
		};
		arbitraryLine.crossSectionSetting = newCrossSectionSetting;
		arbitraryLine.isCrossSectionLoaded = true;
		arbitraryLine.isCrossSectionVisible = true;
		await window["viewer"].generateLineWorksCrossSection(
			arbitraryLine.viewerLineId,
			newCrossSectionSetting,
		);
		commit("updateArbitraryLine", arbitraryLine);
	},

	async loadArbitraryLine({ state }, arbitraryLine) {
		try {
			const arbitraryRes = await axios.get(
				`${import.meta.env.VITE_API_BASE}/arbitraryLinear/${this.state.site.site_uuid}/${arbitraryLine.lineId}`,
				{
					auth: state.authObject,
				},
			);
			const { lineInfo } = arbitraryRes.data;
			const viewerLineId = await window["viewer"].loadArbitraryLineJSON(
				lineInfo.json,
			);
			arbitraryLine.viewerLineId = viewerLineId;
			arbitraryLine.isLoadedInViewer = true;
			arbitraryLine.isLoaded = true;
			arbitraryLine.isVisible = true;
		} catch (err) {
			arbitraryLine.isLoadedInViewer = false;
			arbitraryLine.isLoaded = false;
			arbitraryLine.isVisible = false;
			//Todo inform user properly
			throw err;
		}
	},
	async deleteArbitraryLine({ state, commit }, arbitraryLine) {
		try {
			if (arbitraryLine.isLoadedInViewer) {
				window["viewer"].deleteArbitraryLine(arbitraryLine.viewerLineId);
			}
			if (arbitraryLine.isCrossSectionLoaded) {
				await axios.delete(
					`${import.meta.env.VITE_API_BASE}/crossSection/arbitraryLinear/${this.state.site.site_uuid}/${arbitraryLine.lineId}`,
					{
						auth: state.authObject,
					},
				);
			}

			await axios.delete(
				`${import.meta.env.VITE_API_BASE}/arbitraryLinear/${this.state.site.site_uuid}/${arbitraryLine.lineId}`,
				{
					auth: state.authObject,
				},
			);

			const list = state.arbitraryLineList.filter(
				(line) => line.lineId !== arbitraryLine.lineId,
			);
			commit("setArbitraryLineList", list);
		} catch (e) {
			console.warn(e);
			throw e;
		}
	},

	async updateName(_, { arbitraryLine }) {
		try {
			const res = await axios.put(
				`${import.meta.env.VITE_API_BASE}/arbitraryLinear/${this.state.site.site_uuid}/${arbitraryLine.lineId}`,
				{
					name: arbitraryLine.lineName,
				},
			);
			await axios.put(
				`${import.meta.env.VITE_API_BASE}/crossSection/name/${this.state.site.site_uuid}/${arbitraryLine.crossSectionSetting.id}`,
				{
					name: arbitraryLine.lineName,
				},
			);
			return res;
		} catch (err) {
			console.warn("Arbitrary line Name is not saved");
			//Todo inform user properly
			throw err;
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
