<script setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

/** props定義 */
const props = defineProps({
	entity: Object,
	isRenamingMode: Boolean,
});

/** 関数定義 */
const store = useStore();
const { t } = useI18n();

const deleteLineWorksItem = async () => {
	await store.dispatch("executeWithSpinner", async () => {
		try {
			if (props.entity.lineType === "sima") {
				await store.dispatch("simaLine/deleteSimaLine", props.entity);
			} else if (props.entity.lineType === "arbitrary") {
				await store.dispatch("arbitraryLine/deleteArbitraryLine", props.entity);
			}
			store.commit("set_snackbar", {
				text: `${props.entity.lineName} ${t("REMOVE")} ${t("successful")}`,
				color: "rgba(0, 153, 0, 0.72)",
			});
		} catch (e) {
			store.commit("set_snackbar", {
				text: `${props.entity.lineName} ${t("REMOVE")} ${t("failed")}`,
				color: "rgba(255, 0, 0, 0.72)",
			});
		}
	});
};

const exportSima = async () => {
	await store.dispatch("executeWithSpinner", async () => {
		try {
			const targetJson = window["viewer"].fetchLineWorkJSON(
				props.entity?.viewerLineId,
			);
			window["viewer"].exportToSima(
				props.entity.lineName,
				store.state.site.site_name,
				targetJson,
			);
		} catch (e) {
			console.error(e);
			store.commit("set_snackbar", {
				text: t("LINEWORKS_EXPORT_FAILED", { name: props.entity.lineName }),
				color: "rgba(153, 0, 0, 0.72)",
			});
		}
	});
};

const exportDxf = async () => {
	await store.dispatch("executeWithSpinner", async () => {
		try {
			await store.dispatch(
				"lineWorksCrossSection/exportCrossSection",
				props.entity,
			);
		} catch (e) {
			console.error(e);
			store.commit("set_snackbar", {
				text: t("LINEWORKS_CROSS_SECTION_DOWNLOAD_FAILED", {
					name: props.entity.lineName,
				}),
				color: "rgba(153, 0, 0, 0.72)",
			});
		}
	});
};
</script>

<template>
    <v-list density="compact">
        <!-- 出力 -->
        <v-list-item @click="exportSima" class="list-doing" :disabled="!(entity.isLoaded && entity.isVisible)">
            <v-icon class="mr-4">icon:Export</v-icon>
            <span class="item-name">{{ $t("EXPORT_AS") }}</span>
        </v-list-item>
        <!-- 断面図出力 -->
        <v-list-item @click="exportDxf" class="list-doing" :disabled="!(entity.isCrossSectionVisible)">
            <v-icon class="mr-4">icon:Export</v-icon>
            <span class="item-name">{{ $t("EXPORT_CROSS_SECTION_DXF") }}</span>
        </v-list-item>
        <!-- 名称変更 -->
        <v-list-item @click="toggleRenamingMode" class="list-doing">
            <v-icon class="mr-4">mdi-rename</v-icon>
            <span class="item-name">{{ $t("RENAME") }}</span>
        </v-list-item>
        <!-- 編集 -->
        <v-list-item @click="editLineWorksCrossSection(entity)" class="list-doing"
            :disabled="!(entity.isLoaded && entity.isVisible)">
            <v-icon class="mr-4">icon:Pencil</v-icon>
            <span class="item-name">{{ $t("EDIT_CROSS_SECTION") }}</span>
        </v-list-item>
        <!-- 削除 -->
        <v-list-item @click="deleteLineWorksItem" class="list-delayed">
            <v-icon class="mr-4">icon:Trash-Can</v-icon>
            <span class="item-name">{{ $t("REMOVE") }}</span>
        </v-list-item>
    </v-list>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "LineWorksThreeDotMenu",
    methods: {
        ...mapActions(['lineWorksCrossSection']),
        async editLineWorksCrossSection(entity) {
            const customLineWorkEditDialogEvent = new CustomEvent("openLineWorksEditDialog", { detail: { crossSectionSettings: entity.crossSectionSetting, lineWorksId: this.entity.viewerLineId } });
            window.dispatchEvent(customLineWorkEditDialogEvent);
        },
        toggleRenamingMode() {
            this.$emit('update:isRenamingMode', !this.isRenamingMode)
        },
    },
}
</script>

<style scoped>
.item-name {
    font-size: 13px;
}
</style>