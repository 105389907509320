<template>
    <v-list density="compact">
      <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForFlatGroundEdit" :key="index"
        @click="addControlPoint()">
        <v-list-item-title>
          {{ $t(item.title) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </template>
  
  <script>
export default {
	props: {
		flatGroundId: String,
		position: Object,
	},
	data: () => ({
		itemsForFlatGroundEdit: [{ id: 1, title: "ADD" }],
	}),
	methods: {
		addControlPoint() {
			window["viewer"].addFlatGroundControlPointOnOutline(
				this.flatGroundId,
				this.position,
			);
		},
	},
};
</script>
  
  <style lang="scss" scoped>
  .list-ChartContextMenu:hover {
    background-color: #0064ba !important;
  }
  </style>
  