<!-- 計測データのコンポーネント -->
<script setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

/** props定義 */
const props = defineProps({
	entity: Object,
});

/** 関数定義 */
const store = useStore();
const { t } = useI18n();

const remove = async () => {
	const successMessage = `${t("REMOVE")} ${t("successful")}`;
	const errorMessage = `${t("REMOVE")} ${t("failed")}`;
	await store.dispatch("executeWithSpinner", async () => {
		try {
			// リクエスト
			const res = await store.dispatch(
				"measurement/deleteMeasurement",
				props.entity,
			);
			if (res.status === 204) {
				store.commit("set_snackbar", {
					text: successMessage,
					color: "rgba(0, 153, 0, 0.72)",
				});
			} else {
				// 想定外のステータスコードが返却された場合エラーをスロー
				throw new Error(errorMessage);
			}
		} catch {
			store.commit("set_snackbar", {
				text: errorMessage,
				color: "rgba(153, 0, 0, 0.72)",
			});
		}
	});
};
</script>

<template>
    <span>
        <v-card-actions
            class="py-0"
        >
                <v-btn
                  icon
                  size="24"
                  :disabled="isDisabledVisibilityBtn"
                  @click="toggleMeasurementVisibility"
                  class="ml-0"
                >
                    <v-icon>
                        {{ measurementVisibility ? "icon:View" : "icon:View-Off" }}
                    </v-icon>
                </v-btn>
            <measurement-name
                class="ml-1"
                style="width: 200px;"
                @end-rename="isRenamingMode = false"
                :isRenamingMode="isRenamingMode"
                :measurement="entity"
            >
            </measurement-name>
                <v-btn
                  :disabled="!entity.isLinearVisible"
                  @click="focus('linear')"
                  icon
                  size="24"
                >
                    <v-icon size="small">icon:Search</v-icon>
                </v-btn>
                <v-menu 
                  transition="slide-y-transition"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            icon
                            v-bind="props"
                            size="24"
                        >
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                    </template>
                    <v-list density="compact">
                        <!-- 名称変更 -->
                        <v-list-item @click="toggleRenamingMode">
                            <v-icon class="mr-4">mdi-rename</v-icon>
                            <span style="font-size: 13px;">{{ $t("RENAME") }}</span>
                        </v-list-item>
                        <!-- 削除 -->
                        <v-list-item @click="remove">
                            <v-icon class="mr-4">mdi-delete</v-icon>
                            <span style="font-size: 13px;">{{ $t("REMOVE") }}</span>
                        </v-list-item>
                    </v-list>
                </v-menu>
        </v-card-actions>
        <div class="pl-7" >
            <!-- 距離 -->
            <v-card-actions class="py-0">
                    <v-btn
                      icon
                      size="24"
                      :disabled="isDisabledVisibilityBtn"
                      @click="toggleDistanceVisibility"
                      class="ml-0"
                    >
                        <v-icon>
                            {{ entity.isDistanceVisible ? "icon:View" : "icon:View-Off" }}
                        </v-icon>
                    </v-btn>
                <span class="ml-1">{{ $t("DISTANCE") }}</span>
                  <v-btn
                    class="ml-auto"
                    :disabled="!entity.isDistanceVisible"
                    @click="focus('distance')"
                    icon
                    size="24"
                  >
                      <v-icon size="small">icon:Search</v-icon>
                  </v-btn>
            </v-card-actions>
            <!-- 断面 -->
            <v-card-actions class="py-0">
                    <v-btn
                      icon
                      size="24"
                      :disabled="isDisabledVisibilityBtn"
                      @click="toggleCrossSectionVisibility"
                      class="ml-0"
                    >
                        <v-icon>
                            {{ entity.isCrossSectionVisible ? "icon:View" : "icon:View-Off" }}
                        </v-icon>
                    </v-btn>
                <span class="ml-1">{{ $t("CROSS_SECTION") }}</span>
                  <v-btn
                    class="ml-auto"
                    :disabled="!entity.isCrossSectionVisible"
                    @click="focus('crossSection')"
                    icon
                    size="24"
                  >
                      <v-icon size="small">icon:Search</v-icon>
                  </v-btn>
            </v-card-actions>
        </div>
    </span>
</template>

<script>
import * as cesiumCommon from '@/utils/cesium-common'
import MeasurementName from "./MeasurementName.vue";
import * as constants from '@/constant.js';

export default {
  name: "MeasurementListItem",
  components: {
    MeasurementName 
  },
  data() {
    return {
      isRenamingMode: false
    }
  },
  computed: {
    /**
     * 計測データの表示ステータス
     * 距離または断面のいずれかが表示状態のときにTRUE
     */
    measurementVisibility() {
      return this.entity.isDistanceVisible || this.entity.isCrossSectionVisible;
    },
    /**
     * 表示切り替えボタンの活性制御
     * 表示状態の点群が1件もない場合非活性
     */
    isDisabledVisibilityBtn() {
      return !this.$store.getters.visiblePointCloudList.length;
    }
  },
  watch: {
    async measurementVisibility(newValue) {
      // 計測データの表示ステータスに追従する形で線形の表示ステータスを切り替える
      if (newValue !== this.entity.isLinearVisible) {
        await cesiumCommon.toggleLinearVisibility(this.entity);
      }
    }
  },
  methods: {
    /**
     * 線形データの表示切り替えボタン
     * [ON → OFF]
     * ・線形データと紐づく距離、断面データを全てOFFにする
     * [OFF → ON]
     * ・線形データと紐づく距離、断面データを全てONにする
     */
    toggleMeasurementVisibility() {
      const currentMeasurementVisibility = this.measurementVisibility;
      if (currentMeasurementVisibility === this.entity.isDistanceVisible) {
        this.toggleDistanceVisibility();
      }
      if (currentMeasurementVisibility === this.entity.isCrossSectionVisible) {
        this.toggleCrossSectionVisibility();
      }
    },
    async toggleDistanceVisibility() {
      await cesiumCommon.toggleDistanceVisibility(this.entity);
    },
    toggleCrossSectionVisibility() {
      // 断面表示時にタブがmeasurementViewでない場合、タブを変更する
      if ( !this.entity.isCrossSectionVisible 
        && this.$store.state.chartBoardCurrentTab !== constants.chartBoardTabs.MEASUREMENT.id ) {
        this.$store.commit("setChartBoardCurrentTab", constants.chartBoardTabs.MEASUREMENT);
        this.$nextTick(() => {
          cesiumCommon.toggleCrossSectionVisibility(this.entity);
        });
      } else {
        cesiumCommon.toggleCrossSectionVisibility(this.entity);
      }
    },
    toggleRenamingMode() {
      this.isRenamingMode = !this.isRenamingMode;
    },
    focus(type) {
      if (type === "crossSection") {
        cesiumCommon.showMeasurementCrossSection(this.entity);
      } else {
        window["viewer"].zoomToMeasurement(String(this.entity.measurementId), type);
      }
    },
  },
  beforeUnmount() {
    if ( this.entity.isLinearLoaded 
      || this.entity.isDistanceLoaded
      || this.entity.isCrossSectionLoaded
    ) 
    {
      cesiumCommon.deleteMeasurement(this.entity);

    }
  }
}
</script>