import axios from "axios";
import Measurement from "@/models/Measurement";

// 線形機能で使用するstore
const state = {
	// 線形データ
	measurementList: [],
	// 最後に表示操作を行った任意断面
	activeArbitraryCrossSection: null,
};

const getters = {
	measurementList: (state) => state.measurementList,
};

const mutations = {
	setMeasurementList(state, measurementList) {
		state.measurementList = measurementList;
	},
	deleteMeasurement(state, measurementId) {
		state.measurementList = state.measurementList.filter(
			(item) => item.measurementId != measurementId,
		);
	},
	setActiveArbitraryCrossSection(state, measurement) {
		state.activeArbitraryCrossSection = measurement;
	},
};

const actions = {
	async getMeasurementList({ state, commit }, site_id) {
		const measurementList = [];
		const res = await axios.get(
			`${import.meta.env.VITE_API_BASE}/twoPointsPicker/${site_id}`,
			{
				auth: this.state.authObject,
			},
		);
		for (const measurement of res.data) {
			const currentMeasurement = state.measurementList.find(
				(entity) => entity.measurementId === measurement.two_points_picker_id,
			);
			// すでにリストに存在する場合は更新。そうでない場合は新規追加
			if (currentMeasurement) {
				currentMeasurement.update(measurement);
				measurementList.push(currentMeasurement);
			} else {
				const newMeasurement = new Measurement(
					measurement.two_points_picker_id,
					measurement.two_points_picker_name,
					measurement.start_info,
					measurement.end_info,
				);
				measurementList.push(newMeasurement);
			}
		}
		commit("setMeasurementList", measurementList);
	},
	async deleteMeasurement({ commit }, measurement) {
		const res = await axios.delete(
			`${import.meta.env.VITE_API_BASE}/twoPointsPicker/${measurement.measurementId}`,
		);
		commit("deleteMeasurement", measurement.measurementId);
		return res;
	},
	setActiveArbitraryCrossSection({ commit }, measurement) {
		commit("setActiveArbitraryCrossSection", measurement);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
