<template>
	<v-footer app :height="this.mini ? '36px' : '240px'" color="panel_main" class="pa-0 flex-column">
		<DragableDialog v-if="showDraggableDialog" :title="draggedTab.title">
			<component :is="draggedTab.component"></component>
		</DragableDialog>
		<div class="tab-wrapper d-flex justify-space-between">
			<div background-color="panel_sub">
				<v-btn variant="flat" v-for="tab in tabs()" :key="tab.id"
					:class="['tab-button', { active: currentTab.id === tab.id }]" @click="currentTab = tab">
					{{ $t(tab.title) }}
				</v-btn>
			</div>
			<div class="d-flex justify-end align-center">
				<div v-if="fileName" class="file-name-wrapper d-flex">
					<span class="file-name"> {{ fileName }} </span>
				</div>
				<div class="d-flex">
					<v-btn size="36" variant="text" icon>
						<v-icon @click="toggleSplitDialog()"> icon:New-Window </v-icon>
					</v-btn>
					<v-btn size="36" variant="text" icon>
						<v-icon @click="toggleChartBoardMini()">
							{{ this.mini ? "icon:Chevron-Up" : "icon:Chevron-Down" }}
						</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
		<component :is="currentTab.component" ref="currentTab" style="width: 100%; height: 100%">
		</component>
	</v-footer>
</template>

<script>
import DragableDialog from "./DragableDialog.vue";
import ProfileViewDialog from "./ProfileViewDialog.vue";
import SectionViewDialog from "./SectionViewDialog.vue";
import MeasurementViewDialog from "./MeasurementViewDialog.vue";
import LineSectionViewDialog from "./LineSectionViewDialog.vue";
import LineProfileViewDialog from "./LineProfileViewDialog.vue";

import * as constants from "@/constant.js";
// import SectionDialog from "../SectionDialog.vue";

export default {
	components: {
		DragableDialog,
		ProfileViewDialog,
		SectionViewDialog,
		MeasurementViewDialog,
		LineSectionViewDialog,
		LineProfileViewDialog,
	},
	name: "ChartBoard",
	watch: {
		mini() {
			this.$nextTick(() => {
				// call request render
				window["viewer"].requestRender();
			});
		},
	},
	data: () => ({
		mini: false,
		showDraggableDialog: false,
		title: "Hello from Parent",
		alltabs: Object.values(constants.chartBoardTabs),
	}),
	methods: {
		toggleChartBoardMini() {
			this.mini = !this.mini;
		},
		// updateTab() {
		//  },
		toggleSplitDialog() {
			this.showDraggableDialog = !this.showDraggableDialog;
			if (this.showDraggableDialog) {
				this.draggedTab = Object.assign({}, this.currentTab);
				this.currentTab = this.tabs()[0];
			} else {
				this.draggedTab = {};
			}
		},

		tabs() {
			if (this.showDraggableDialog) {
				return this.alltabs.filter((tab) => tab.id !== this.draggedTab.id);
			} else {
				return this.alltabs;
			}
		},
	},
	created() {
		console.log("Chartboard Created func");
		this.currentTab = constants.chartBoardTabs.PROFILE;
	},
	mounted() {
		console.log("Chartboard Mounted func");
	},
	updated() {
		console.log("Updated");
		if (this.currentTab.id === constants.chartBoardTabs.MEASUREMENT.id) {
			// チャートボードのDOM更新完了後に子コンポーネントのメソッドを実行
			this.$refs.currentTab.update();
		}
	},
	onresize(size) {
		console.log("resize called", size);
	},
	computed: {
		draggableparams: function () {
			return { title: this.draggedTab.title };
		},
		currentTab: {
			get() {
				return this.$store.state.chartBoardCurrentTab;
			},
			set(value) {
				this.$store.commit("setChartBoardCurrentTab", value);
			},
		},
		draggedTab: {
			get() {
				return this.$store.state.draggedTab;
			},
			set(value) {
				this.$store.commit("setDraggedTab", value);
			},
		},
		fileName() {
			if (this.currentTab.id === constants.chartBoardTabs.MEASUREMENT.id) {
				// 任意断面ビュー
				const activeArbitraryCrossSection =
					this.$store.state.measurement.activeArbitraryCrossSection;
				return activeArbitraryCrossSection?.measurementName || "";
			} else if (
				this.currentTab.id === constants.chartBoardTabs.LINE_SECTION.id
			) {
				// 線形断面ビュー
				const activeLineCrossSection = this.$store.state.activeLineCrossSection;
				console.log("activeLineCrossSection", activeLineCrossSection);
				return activeLineCrossSection?.lineName || "";
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.v-footer {
	align-items: start !important;
	transition: all 0.2s;
}

.tab-wrapper {
	width: 100%;
}

.list-ChartContextMenu:hover {
	background-color: #0064ba !important;
}

.tab-button {
	cursor: pointer;
	color: inherit;
	background-color: transparent;
	border-radius: 0;
}

.tab-button:hover {
	background: #302f2f;
}

.tab-button.active {
	background-color: transparent;
	color: #0064ba;
	border-bottom: 0.2em solid #0064ba;
}

.file-name-wrapper {
	min-width: 100px;
}

.file-name {
	display: inline-block;
	border-radius: 4px;
	padding: 4px 8px;
	margin: 1px 1px 1px auto;
	background-color: white;
	color: #21b6fc;
	word-wrap: break-word;
}
</style>
