const hasValue = (v) => {
	return v !== null && v !== undefined && v !== "";
};

/**
 * 汎用的に使用可能なフォームバリデーションルールを定義する
 */
const rules = {
	/**
	 * 入力値のフォーマットが不正でなければチェックOK
	 */
	numberFormat: (v) => {
		return /^(?!-?0[0-9])-?[0-9]+(\.\d+)?$/.test(v);
	},
	/**
	 * 入力値の桁数が引数以下であればチェックOK
	 */
	maxLength: (max) => {
		if (typeof max === "number") {
			// return v => v.length <= max;}
			return (v) => {
				if (!hasValue(v)) return false;
				else return v.length <= max;
			};
		}
	},
	/**
	 * 入力値の桁数が引数以上であればチェックOK
	 */
	minLength: (min) => {
		if (typeof min === "number") {
			// return v => v.length >= min;}
			return (v) => {
				if (!hasValue(v)) return false;
				if (v === "") false;
				else return v.length >= min;
			};
		}
	},
	/**
	 * 入力値の小数点以下の桁数が引数以下であればチェックOK
	 */
	decimalLength: (max) => {
		if (typeof max === "number") {
			const pattern = new RegExp(`^(?!.*[.]\\d{${max + 1},}$).+$`);
			return (v) => pattern.test(v);
		}
		return "Decimal Length does not match";
	},
	/**
	 * 入力値が引数の数値以上であればチェックOK
	 */
	orMore: (baseNumber) => {
		if (typeof baseNumber === "number") {
			return (v) => {
				if (v === "") return false;
				else return Number(v) >= Number(baseNumber);
			};
		}
		return "number should be greater";
	},
	/**
	 * 入力値が引数の数値以下であればチェックOK
	 */
	orLess: (baseNumber) => {
		if (typeof baseNumber === "number") {
			return (v) => {
				if (v === "") return false;
				else return Number(v) <= Number(baseNumber);
			};
		}
		return "number should be smaller";
	},
};

export default rules;
