<template>
  <div>
    <v-card-text class="py-0">
      <v-btn size="36" variant="text" icon @click="toggleExpansion" class="ml-0">
        <v-icon> mdi-play {{ expansion ? "mdi-rotate-90" : "" }} </v-icon>
      </v-btn>
      <span>{{ $t("NOT_STARTED") }}</span>
    </v-card-text>
    <v-expand-transition>
      <div v-show="expansion" class="list mx-3 mb-3" id="point_cloud_list">
        <v-card-text v-if="point_cloud_list.length == 0" class="pa-0 pl-10">
          {{ $t("NO_DATA") }}
        </v-card-text>
        <draggable animation="553" handle=".handle" v-model="pointCloudListDraggable"
          :item-key="(item) => item.getId()">
          <template #item="{ element }">
            <v-card-text class="pa-0 handle">
              <point-cloud-list-item :entity="element" :draggable="true"></point-cloud-list-item>
            </v-card-text>
          </template>
        </draggable>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";
import PointCloudListItem from "./PointCloudListItem.vue";

export default {
	components: {
		draggable,
		PointCloudListItem,
	},
	name: "PointCloudList",
	data() {
		return {
			expansion: false,
		};
	},
	computed: {
		...mapState(["point_cloud_list"]),
		pointCloudListDraggable: {
			get() {
				return this.point_cloud_list;
			},
			set(value) {
				this.set_point_cloud_list(value);
			},
		},
	},
	methods: {
		...mapMutations(["set_point_cloud_list"]),
		toggleExpansion() {
			this.expansion = !this.expansion;
		},
	},
};
</script>

<style lang="scss" scoped>
.list {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
</style>
