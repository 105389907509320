<template>
  <v-list density="compact">
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForRoadEdit" :key="index"
      @click="onCMClickForRoadEdit($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import * as lockUtils from "@/utils/lock-utils";
import * as constants from "@/constant.js";

export default {
	props: {
		pathId: String,
		pointId: String,
		showRemoveOption: Boolean,
	},
	data: function () {
		return {
			itemsForRoadEdit: [
				{ id: 1, title: "Create Arc" },
				{ id: 2, title: "ADD" },
				...(this.showRemoveOption ? [{ id: 3, title: "Remove" }] : []),
				{ id: 5, title: "Update" },
				{ id: 6, title: "Finishdrawing" },
				{ id: 7, title: "Undo" },
				{ id: 8, title: "Redo" },
				...(import.meta.env.VITE_ENABLE_SEGMENT_SETTING_CONTEXT_MENU === "true"
					? [{ id: 9, title: "SegmentSetting" }]
					: []),
			],
		};
	},
	watch: {
		showRemoveOption: function (showRemoveOption) {
			this.itemsForRoadEdit = [
				{ id: 1, title: "Create Arc" },
				{ id: 2, title: "ADD" },
				{ id: 3, title: "Remove" },
				{ id: 5, title: "Update" },
				{ id: 6, title: "Finishdrawing" },
				{ id: 7, title: "Undo" },
				{ id: 8, title: "Redo" },
				...(import.meta.env.VITE_ENABLE_SEGMENT_SETTING_CONTEXT_MENU === "true"
					? [{ id: 9, title: "SegmentSetting" }]
					: []),
			];

			if (!showRemoveOption) {
				const itemIndex = this.itemsForRoadEdit.findIndex(
					(item) => item.id === 3,
				);
				if (itemIndex > -1) {
					this.itemsForRoadEdit.splice(itemIndex, 1);
				}
			}
		},
	},
	methods: {
		async onCMClickForRoadEdit(e, id) {
			e.preventDefault();
			const numberOFPathPoints = window["viewer"].getRoadPointLength(
				this.pathId,
			);
			const clickedIndex = window["viewer"].getControlPointIndexById(
				this.pathId,
				this.pointId,
			);
			switch (id) {
				case 1:
					window["viewer"].updateBendToCircle(this.pathId, this.pointId);
					break;
				case 2:
					window["viewer"].addControlPointOnRoad(this.pathId, this.pointId);
					window.dispatchEvent(
						new CustomEvent("addPointEvent", {
							detail: {
								id: this.pathId,
								entityUnderEdit: constants.objectType.ROAD,
								pointIndex: clickedIndex,
							},
						}),
					);
					break;
				case 3:
					window["viewer"].removeControlPoint(this.pathId, this.pointId);
					window.dispatchEvent(
						new CustomEvent("deletePointEvent", {
							detail: {
								id: this.pathId,
								entityUnderEdit: constants.objectType.ROAD,
								pointIndex: clickedIndex,
							},
						}),
					);
					break;
				case 5:
					window.dispatchEvent(
						new CustomEvent("openPropertyDialog", {
							detail: {
								id: this.pathId,
								type: 3,
								pointId: this.pointId,
								entityUnderEdit: constants.objectType.ROAD,
								disabilityConditions: "Z",
								origin: "CesiumView",
							},
						}),
					);
					break;
				case 6:
					// eslint-disable-next-line no-case-declarations
					const roads = this.$store.state.objects.find(
						(road) => road.id === this.pathId,
					);
					if (roads) {
						this.$store.dispatch("executeWithSpinner", async () => {
							this.$store.dispatch("endEditMode");
							// ロックを解放
							const lockReleased = await lockUtils.releaseLock(roads.cid);
							if (!lockReleased) return;
							window["viewer"].enableRoadEdit(false, roads.id, () => {});
							roads.edit = false;
						});
					}
					window["viewer"].clearCommandManager();
					break;
				case 7:
					window["viewer"].undo();
					break;
				case 8:
					window["viewer"].redo();
					break;
				case 9:
					//openup dialog box
					console.log("clicked pont", {
						id: this.pathId,
						type: 5,
						clickedPointIndex: this.pointId,
						totalPoints: numberOFPathPoints,
					});
					window.dispatchEvent(
						new CustomEvent("openPropertyDialog", {
							detail: {
								id: this.pathId,
								type: 5,
								clickedPointIndex: clickedIndex,
								totalPoints: numberOFPathPoints,
							},
						}),
					);
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>
