import { h } from "vue";
import HelpIcon from "./HelpIcon.vue";
import UserIcon from "./UserIcon.vue";
import SettingIcon from "./SettingIcon.vue";
import MountainIcon from "./MountainIcon.vue";
import CubesIcon from "./CubesIcon.vue";
import ArrowRightIcon from "./ArrowRightIcon.vue";
import ChevronRightIcon from "./ChevronRightIcon.vue";
import NewWindowIcon from "./NewWindowIcon.vue";
import AddIcon from "./AddIcon.vue";
import HomeIcon from "./HomeIcon.vue";
import ChevronLeftIcon from "./ChevronLeftIcon.vue";
import TrashCanIcon from "./TrashCanIcon.vue";
import ChevronDownIcon from "./ChevronDownIcon.vue";
import ChevronUpIcon from "./ChevronUpIcon.vue";
import OverflowMenuVerticalIcon from "./OverflowMenuVerticalIcon.vue";
import SearchIcon from "./SearchIcon.vue";
import ViewIcon from "./ViewIcon.vue";
import ViewOffIcon from "./ViewOffIcon.vue";
import BullIcon from "./BullIcon.vue";
import PencilIcon from "./PencilIcon.vue";
import ExportIcon from "./ExportIcon.vue";

const aliases = {
	Help: HelpIcon,
	User: UserIcon,
	Setting: SettingIcon,
	Mountain: MountainIcon,
	Cubes: CubesIcon,
	"Arrow-Right": ArrowRightIcon,
	"Chevron-Right": ChevronRightIcon,
	"New-Window": NewWindowIcon,
	Add: AddIcon,
	Home: HomeIcon,
	"Chevron-Left": ChevronLeftIcon,
	"Trash-Can": TrashCanIcon,
	"Chevron-Down": ChevronDownIcon,
	"Chevron-Up": ChevronUpIcon,
	"Overflow-Menu-Vertical": OverflowMenuVerticalIcon,
	Search: SearchIcon,
	View: ViewIcon,
	"View-Off": ViewOffIcon,
	Bull: BullIcon,
	Pencil: PencilIcon,
	Export: ExportIcon,
};

const custom = {
	component: (props) =>
		h(props.tag, [h(aliases[props.icon], { class: "v-icon__svg" })]),
};

export { custom, aliases };
