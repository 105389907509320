<template>
    <div>
        <v-dialog :scrim="false" v-model="showAddLineWorksDialog" width="40%">
            <select-line-works-actions @data-to-parent="receiveDataFromChild" @close="closeDialog()"
                @open-terrain-data="openTerrainData" />
        </v-dialog>
        <v-dialog v-model="showTerrainDataDialog" width="600px" persistent>
        <add-terrain-data @upload="uploadTerrainData" @close="closeTerrainData"
            :allowedFileTypes="allowedFileTypes"
            :is_show="showTerrainDataDialog" />
        </v-dialog>
    </div>
</template>

<script>
import SelectLineWorksActions from "../AddData/SelectLineWorksActions.vue";
import addTerrainData from "../AddData/addTerrainData.vue";
import axios from "axios";
import { mapMutations } from "vuex";

export default {
	components: {
		SelectLineWorksActions,
		addTerrainData,
	},
	props: {
		modelValue: Boolean,
	},
	data() {
		return {
			type: "",
			showTerrainDataDialog: false,
			axisShow: false,
			allowedFileTypes: "",
		};
	},
	computed: {
		showAddLineWorksDialog: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("update:modelValue", value);
			},
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		closeDialog() {
			this.$emit("close");
		},
		receiveDataFromChild() {
			this.$emit("data-to-parent");
		},
		openTerrainData(allowedFileTypes, axisShow) {
			// 選択ダイアログを閉じる
			this.showAddLineWorksDialog = false;
			// アップロードダイアログを開く
			this.allowedFileTypes = allowedFileTypes;
			this.axisShow = axisShow;
			this.showTerrainDataDialog = true;
		},
		closeTerrainData() {
			this.allowedFileTypes = "";
			this.axisShow = false;
			this.showTerrainDataDialog = false;
		},
		async uploadTerrainData(dialogData) {
			if (dialogData) {
				this.$store.dispatch("updateIsLoading", true);
				const fd = new FormData();
				fd.append("file", dialogData[0]);
				fd.append("name", dialogData[1]);
				fd.append("date", dialogData[2]);
				fd.append("time", dialogData[3] + ":00");

				try {
					const res = await axios.post(
						`${import.meta.env.VITE_API_BASE}/linearfile/${this.$route.query.siteId}`,
						fd,
						{
							auth: this.$store.state.authObject,
						},
					);
					this.$store.commit("simaLine/addSimaLine", res.data);
				} catch (e) {
					if (typeof e.toJSON === "function" && e.toJSON().status == 504) {
						this.set_snackbar({
							text: `${dialogData[1]}\n${this.$t("entity_upload_wait_msg")}`,
							color: "rgba(255, 215, 0, 0.72)",
						});
					} else if (
						typeof e.toJSON === "function" &&
						e.toJSON().status == 422
					) {
						this.set_snackbar({
							text: `${this.$t("INVALID_SIMA_UPLOAD_FAILED", { name: dialogData[1] })}`,
							color: "rgba(153, 0, 0, 0.72)",
						});
					} else {
						this.set_snackbar({
							text: `${dialogData[1]} ${this.$t("UPLOAD")} ${this.$t("failed")}`,
							color: "rgba(153, 0, 0, 0.72)",
						});
					}
				} finally {
					this.$store.dispatch("updateIsLoading", false);
					this.closeTerrainData();
				}
			}
		},
	},
};
</script>
