<template>
  <div>
    <v-card-text class="py-0">
      <v-btn size="36" variant="text" icon @click="toggleExpansion" class="ml-0">
        <v-icon> mdi-play {{ expansion ? "mdi-rotate-90" : "" }} </v-icon>
      </v-btn>
      <span>{{ $t("LINEWORK_DATA") }}</span>
    </v-card-text>
    <v-expand-transition>
      <div v-show="expansion" class="list ml-4 mr-3 mb-3">
        <v-card-text v-if="linearList().length == 0" class="pa-0 pl-10">
          {{ $t("NO_DATA") }}
        </v-card-text>
        <v-card-text v-for="entity in linearList()" :key="getKey(entity)" class="pa-0 mb-2">
          <line-works-list-item :entity="entity"></line-works-list-item>
        </v-card-text>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LineWorksListItem from "./LineWorksListItem.vue";
export default {
	components: {
		LineWorksListItem,
	},
	name: "LineWorksList",
	data() {
		return {
			expansion: false,
		};
	},
	methods: {
		...mapGetters("arbitraryLine", ["arbitraryLineList"]),
		...mapGetters(["linearList"]),
		toggleExpansion() {
			this.expansion = !this.expansion;
		},
		getKey(entity) {
			return `${entity.lineType}_${entity.lineId}`;
		},
	},
};
</script>

<style lang="scss" scoped>
.list {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
</style>
