<template>
  <v-card class="px-4 pt-2 pb-4" color="rgba(41, 41, 47, 0.8)">
    <v-card-title> {{ $t("ADD_LINEWORK_INFO") }} </v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-table density="compact" style="background-color: rgba(41, 41, 47, 0)">
        <tbody>
            <tr>
            <td class="linear-name">
                {{ $t("ArbitraryLinename") }}
            </td>
            <td>
                <v-text-field hide-details density="compact" v-model="name" :rules="[rules.validName]" autofocus />
            </td>
            </tr>
        </tbody>
        </v-table>
        <v-container>
          <v-row class="justify-center">
              <line-works-cross-section-input :crossSectionData="crossSectionData"></line-works-cross-section-input>
          </v-row>
          <v-row>
            <v-card-actions class="full-width justify-end mt-2">
              <v-btn variant="flat" @click="closeDialog" color="red">
                {{ $t("CANCEL") }}
              </v-btn>
              <v-btn variant="flat" @click="addArbitraryLine()" color="primary"
                :disabled="!isValid || addArbitraryLineDisabled || isArbitraryAdded">
                {{ $t("ADD") }}
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import rules from "@/utils/validation-rules.js";
import { mapMutations } from "vuex";
import { i18n } from "@/i18n.js";
import { defaultLineWorksCrossSection } from "./default";
import LineWorksCrossSectionInput from "./LineWorksCrossSectionInput.vue";

export default {
	name: "AddArbitraryLineCrossSectionDialog",
	components: { LineWorksCrossSectionInput },
	props: {
		viewerLineId: String,
		type: Number,
	},
	data: () => ({
		isValid: false,
		name: "",
		crossSectionData: defaultLineWorksCrossSection(),
		isVisible: true,
		isArbitraryAdded: false,
		rules: {
			...rules,
			required: (value) => !!value || "*",
			validName: (v) => !/[~@#$^*()+=[\]{}|\\,?:<>!'"/;&₹€`%]/.test(v),
		},
	}),
	computed: {
		addArbitraryLineDisabled: function () {
			return this.name === null || this.name === "" || /^\s*$/.test(this.name);
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		resetProperty() {
			this.crossSectionData = defaultLineWorksCrossSection();
			this.isVisible = false;
			this.isValid = false;
			this.isArbitraryAdded = false;
		},
		closeDialog() {
			window["viewer"].cancelArbitraryCreation(this.viewerLineId);
			this.$emit("close", false);
		},
		async addArbitraryLine() {
			this.isArbitraryAdded = true;
			try {
				// ローディング開始
				await this.$store.dispatch("executeWithSpinner", async () => {
					try {
						const data = {
							lineInfo: {
								viewerLineId: this.viewerLineId,
								label: this.name,
								json: window["viewer"].getArbitraryLineJSON(this.viewerLineId),
								siteId: this.$route.query.siteId,
								isVisible: this.isVisible,
							},
							crossSectionSetting: {
								leftWidth: this.crossSectionData.leftWidth,
								rightWidth: this.crossSectionData.rightWidth,
								interpolationPitch: this.crossSectionData.interpolationPitch,
								samplingSize: this.crossSectionData.samplingSize,
							},
						};
						await this.$store.dispatch("arbitraryLine/addArbitraryLine", data);
					} catch (e) {
						window["viewer"].deleteArbitraryLine(this.viewerLineId);
						this.set_snackbar({
							text: i18n.tc("LINEWORKS_CREATION_FAILED", 1, {
								name: this.name,
							}),
							color: "rgba(153, 0, 0, 0.72)",
						});
					}
				});
			} finally {
				this.$emit("close", false);
			}
		},
	},
};
</script>

<style scoped>
.linear-name {
  width: 40%;
}

.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: 2em;
}

.pt-2 {
  padding-top: 2em;
}

.full-width {
  width: 100%;
}
</style>