<!-- 編集処理ステータスを表示する -->
<template>
  <!-- 処理ステータス -->
  <div class="d-flex align-center">
    <!-- エラー -->
    <div v-show="selectIcon === icon.error">
      <ErrorTooltip placement="bottom" :triggers="['hover']">
          <div 
            class="process-error rounded-circle"
          >
            <v-icon
              class="process-error-icon"
              color="white"
              size="12px"
            >
              fa-exclamation
            </v-icon>
          </div>
          <template #popper>
            <span class="text-white">{{ $t("UPLOAD_ERROR") }}</span>
          </template>
      </ErrorTooltip>
    </div>
    <!-- 処理中 -->
    <div v-show="selectIcon === icon.processing">
      <InfoTooltip placement="bottom" :triggers="['hover']">
          <v-icon
            class="rounded-circle"
            size="20"
          >
          mdi-progress-upload
          </v-icon>
          <template #popper>
            <span class="text-black">{{ $t("UPLOADING") }}</span>
          </template>
      </InfoTooltip>
    </div>
  </div>
</template>

<script>
import InfoTooltip from "@/components/common/InfoTooltip";
import ErrorTooltip from "@/components/common/ErrorTooltip";

export default {
	components: {
		InfoTooltip,
		ErrorTooltip,
	},
	props: {
		status: {
			required: true,
			type: String,
		},
		processingStatus: {
			required: true,
			type: Object,
		},
	},
	data() {
		return {
			icon: {
				processing: "processing",
				error: "error",
			},
		};
	},
	computed: {
		/**
		 * 表示するアイコン名を返す
		 */
		selectIcon() {
			if (this.isError) return this.icon.error;
			else if (this.isProcessing) return this.icon.processing;
			else return "";
		},
		/**
		 * 処理中かチェックする
		 */
		isProcessing() {
			if (this.processingStatus.PROCESSING.includes(this.status)) {
				return true;
			} else {
				return false;
			}
		},
		/**
		 * 処理エラーが発生しているかチェックする
		 */
		isError() {
			if (this.processingStatus.ERROR.includes(this.status)) {
				return true;
			} else {
				return false;
			}
		},
	},
};
</script>

<style lang="sass" scoped>
// 共通設定
.v-icon
  min-width: 0px
// 処理ステータスエラー
.process-error
  background-color: #F44336
  width: 18px
  height: 18px
  text-align: center
.process-error-icon
  top: -2px
  line-height: 18px
// ツールチップの背景色
:global(.process-error-tooltip) 
  background-color: #F44336 !important
:global(.processing-tooltip) 
  background-color: white!important
</style>