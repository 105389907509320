<script setup>
import { ref, computed, watch } from "vue";
import validationRules from "@/utils/validation-rules.js";

/**
 * 機能概要
 * ・表示用（ユーザー入力用）の勾配値が変更された場合、
 *   viewer用の勾配値に変換し親コンポーネントに反映する。
 *
 * ・親コンポーネントから側でviewer用の勾配値が変更された場合、
 *   suffixToDisplayで指定された単位の表示値用勾配値に変換する。
 *
 * ・表示値用の単位は 「1/n」「%」「°」 に対応。
 *   単位が変更される場合はviewer用の勾配値は変更されず表示値のみ変更する。
 */

const props = defineProps({
	isEditable: Boolean,
	slopeValue: [Number, String],
	suffixToDisplay: String,
	maxValue: Number,
	minValue: Number,
	decimalLength: Number,
	unValidZero: Boolean,
	step: Number,
	modelValue: [Number, String],
	variant: {
		type: String,
		default: "underlined",
	},
});

const emit = defineEmits(["update:model-value", "blur"]);

/** リアクティブデータ */
// viewer用の勾配値。親コンポーネントのv-modelと同期する。
const viewerInputSlopeValue = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit("update:model-value", value);
	},
});

// ユーザー入力用の勾配値
const userInputSlopeValue = ref(0);

// 入力ボックスの参照を取得
const textbox = ref();

/** ユーティリティ関数 */
const roundToLength = (originalValue, decimalLength) =>
	Number(originalValue).toFixed(decimalLength);

// 表示値が閾値外の場合に調整する
const adjustValue = () => {
	let adjustedValue = userInputSlopeValue.value;
	// 最大値を超える場合は最大値にする
	if (adjustedValue > props.maxValue) {
		adjustedValue = props.maxValue;
	}
	// 最小値を下回る場合は最小値にする
	else if (adjustedValue < props.minValue) {
		adjustedValue = props.minValue;
	}
	// 小数点以下の桁数が所定の桁数を超える場合は切り捨てる
	const parts = adjustedValue.toString().split(".");
	if (parts.length > 1 && parts[1].length > props.decimalLength) {
		adjustedValue = roundToLength(adjustedValue, props.decimalLength);
	}
	if (adjustedValue !== userInputSlopeValue.value) {
		userInputSlopeValue.value = adjustedValue;
		updateViewerValueByUserInput();
	}
};

/** viewer用の勾配値に対する処理 */
const convertToSlopeValue = (userInputValue) => {
	let convertedValue;
	switch (props.suffixToDisplay) {
		case "1/n":
			//given ratio return ratio (Y/X not considered)
			convertedValue = Number(userInputValue);
			break;
		case "%":
			//given percent return ratio (x/y)
			if (userInputValue) {
				convertedValue = 100 / userInputValue;
			} else {
				convertedValue = 0;
			}
			break;
		case "°":
			//given degree return ratio
			convertedValue = Math.tan(userInputValue * (Math.PI / 180));
			break;
	}
	return convertedValue;
};

const updateViewerValueByUserInput = () => {
	viewerInputSlopeValue.value = convertToSlopeValue(userInputSlopeValue.value);
};

/** ユーザー入力用の勾配値に対する処理 */
const convertToUserInput = (originalValue) => {
	let convertedValue;
	switch (props.suffixToDisplay) {
		case "1/n":
			//given ratio return ratio (Y/X not considered)
			convertedValue = roundToLength(originalValue, props.decimalLength);
			break;
		case "%":
			//given ratio return percent
			if (originalValue) {
				convertedValue = roundToLength(
					100 / originalValue,
					props.decimalLength,
				);
			} else {
				convertedValue = originalValue;
			}
			break;
		case "°":
			//given ratio return degree
			convertedValue = roundToLength(
				(Math.atan(1 / originalValue) * 180) / Math.PI,
				props.decimalLength,
			);
			break;
	}
	return convertedValue;
};

const updateUserInputByViewerValue = () => {
	userInputSlopeValue.value = convertToUserInput(viewerInputSlopeValue.value);
};

/** watch */
let isSlopeValueChangedByUserInput = false;
watch(viewerInputSlopeValue, () => {
	if (!isSlopeValueChangedByUserInput) {
		// 表示用の勾配値の更新
		updateUserInputByViewerValue();
		adjustValue();
	} else {
		// ユーザーの入力値が上書きされてしまうため。ユーザー入力をトリガーにviewer用の勾配値が更新された場合は表示値を更新しない。
		isSlopeValueChangedByUserInput = false;
	}
});

watch(
	() => props.suffixToDisplay,
	() => {
		updateUserInputByViewerValue();
	},
);

/** テンプレートで使用する関数 */

// 表示値を更新する
const updateDisplaySlopeValue = (value) => {
	isSlopeValueChangedByUserInput = true;
	userInputSlopeValue.value = roundToLength(value, props.decimalLength);
	updateViewerValueByUserInput();
};

const onChange = (value) => {
	// ユーザー入力を数値変換して更新
	let updateValue = "";
	if (value !== "") {
		const numValue = Number.parseFloat(value);
		updateValue = numValue;
	}
	// ユーザービリティを考慮しユーザー入力中は小数点以下のフォーマット調整等行わず、blurイベントで行う
	userInputSlopeValue.value = updateValue;
	// viewer用の勾配値の更新
	isSlopeValueChangedByUserInput = true;
	updateViewerValueByUserInput();
};

const onBlur = () => {
	if (textbox.value.isValid) {
		updateDisplaySlopeValue(userInputSlopeValue.value);
		emit("blur");
	}
};

const classObject = computed(() => {
	let enableBorderBottom = false;
	if (props.variant !== "underlined" && props.variant !== "outlined") {
		// underlinedとoutlined以外はエラー時に下線部が赤くならないため手動で追加
		enableBorderBottom = true;
	}
	return {
		"show-error": enableBorderBottom,
	};
});

const rules = {
	...validationRules,
	required: (v) => (v !== null && v !== "" && v !== undefined) || "*",
	isZero: (v) => (props.unValidZero === true ? true : v !== 0 || "*"),
};

defineExpose({ adjustValue, updateDisplaySlopeValue });

/** 初期化処理 */
updateUserInputByViewerValue();
adjustValue();
</script>

<template>
	<v-text-field :variant="variant" :class="classObject" hide-details density="compact" type="number" :step="step"
		:suffix="suffixToDisplay" :disabled="!isEditable" @blur="onBlur" :rules="!isEditable ? [] :
			[
				rules.required,
				rules.isZero,
				rules.orLess(maxValue),
				rules.orMore(minValue),
				rules.decimalLength(decimalLength),
				rules.numberFormat
			]" @update:model-value="onChange" :model-value="userInputSlopeValue" ref="textbox" />
</template>

<style lang='scss' scoped>
div.show-error:deep(div.v-field.v-field--error) {
	div.v-field__outline {
		border-bottom: solid;
	}
}
</style>