import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";
import "@fortawesome/fontawesome-free/css/all.css";
import { fa } from "vuetify/iconsets/fa";
import "@mdi/font/css/materialdesignicons.css";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import { custom } from "../components/icons/customIcon";

const vuetify = createVuetify({
	components,
	directives,
	theme: {
		defaultTheme: "dark",
		themes: {
			dark: {
				colors: {
					primary: "#0064BA",
					header: "#000000",
					panel_main: "#29292f",
					panel_sub: "#31323a",
					highlight_primary: "#057F00",
				},
			},
		},
	},
	icons: {
		defaultSet: "mdi",
		aliases,
		sets: {
			mdi,
			fa,
			icon: custom,
		},
	},
});

export default vuetify;
