<template>
	<span>
		<v-card-actions class="py-0 item-list">
			<v-btn icon size="24" @click="toggleLineWorksVisibility" class="ml-0">
				<v-icon>
					{{ this.isVisible ? "icon:View" : "icon:View-Off" }}
				</v-icon>
			</v-btn>
			<line-works-name @end-rename="isRenamingMode = false" :isRenamingMode="isRenamingMode" :entity="entity">
			</line-works-name>
			<v-btn @click="focus" :disabled="!entity.isVisible" icon size="24">
				<v-icon size="small">icon:Search</v-icon>
			</v-btn>
			<v-menu>
				<template v-slot:activator="{ props }">
					<v-btn icon v-bind="props" size="24">
						<v-icon> icon:Overflow-Menu-Vertical </v-icon>
					</v-btn>
				</template>
				<line-works-three-dot-menu v-if="entity.isCrossSectionLoaded" :entity="entity"
					v-model:isRenamingMode="isRenamingMode"></line-works-three-dot-menu>
				<line-works-new-cross-section-three-dot-menu v-else-if="!entity.isCrossSectionLoaded"
					:entity="entity"></line-works-new-cross-section-three-dot-menu>
			</v-menu>
		</v-card-actions>
		<div class="pl-7 item-list">
			<!-- 断面 -->
			<v-card-actions class="py-0">
				<v-btn icon size="24" @click="toggleCrossSectionVisibility" class="ml-0">
					<v-icon>
						{{ entity.isCrossSectionVisible ? "icon:View" : "icon:View-Off" }}
					</v-icon>
				</v-btn>
				<span class="ml-2">{{ $t("CROSS_SECTION") }}</span>
			</v-card-actions>
		</div>
	</span>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import LineWorksName from "./LineWorksName.vue";
import LineWorksThreeDotMenu from "./LineWorksThreeDotMenu.vue";
import LineWorksNewCrossSectionThreeDotMenu from "./LineWorksNewCrossSectionThreeDotMenu.vue";

export default {
	name: "LineWorksListItem",
	components: {
		LineWorksName,
		LineWorksThreeDotMenu,
		LineWorksNewCrossSectionThreeDotMenu,
	},
	props: {
		entity: Object,
	},
	data() {
		return {
			isRenamingMode: false,
		};
	},
	computed: {
		isVisible() {
			return this.entity.isVisible;
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		...mapActions("arbitraryLine", ["loadArbitraryLine"]),
		...mapActions("simaLine", ["loadSimaLine"]),
		async toggleLineWorksVisibility() {
			if (!this.entity.isVisible) {
				// 線形をONにする
				await this.linearVisibilityOn();
			} else {
				// 線形をOFFにする
				if (this.entity.lineType === "arbitrary") {
					window["viewer"].toggleArbitraryLineVisibility(
						this.entity.viewerLineId,
						false,
					);
				} else if (this.entity.lineType === "sima") {
					window["viewer"].setSIMAVisibility(this.entity.viewerLineId, false);
				}
				// 線形がOFF時は断面が破棄されるため断面読み込み済みのフラグをリセットする
				// 断面が表示中であれば非表示にする
				if (this.entity.isCrossSectionVisible) {
					this.disposeLineWorksCrossSection(this.entity.viewerLineId);
					this.entity.isCrossSectionVisible = false;
				}
				this.entity.isVisible = false;
			}
		},
		disposeLineWorksCrossSection(lineId) {
			window["viewer"].disposeLineWorksCrossSection(lineId);
			if (this.$store.state.activeLineCrossSection?.viewerLineId === lineId) {
				this.$store.dispatch("setActiveLineCrossSection", null);
			}
		},
		// 線形と断面の表示状態を切り替える
		async toggleCrossSectionVisibility() {
			if (!this.entity.isCrossSectionVisible) {
				// 断面をONにする
				if (!this.entity.isVisible) {
					// 線形がOFFであればONに切り替える
					try {
						await this.linearVisibilityOn();
					} catch {
						return;
					}
				}
				try {
					// 断面が作成されていなければ作成ダイアログを表示
					if (!this.entity.isCrossSectionLoaded) {
						const event = new CustomEvent("openPropertyDialog", {
							detail: {
								id: this.entity.lineId,
								type: 10,
								name: this.entity.lineName,
							},
						});
						window.dispatchEvent(event);
					} else {
						await this.$store.dispatch("executeWithSpinner", async () => {
							await window["viewer"].generateLineWorksCrossSection(
								this.entity.viewerLineId,
								this.entity.crossSectionSetting,
							);
							this.entity.isCrossSectionVisible = true;
						});
					}
				} catch (e) {
					console.error(e);
				}
			} else {
				// 断面をOFFにする
				try {
					this.disposeLineWorksCrossSection(this.entity.viewerLineId);
					this.entity.isCrossSectionVisible = false;
				} catch (e) {
					console.error(e);
				}
			}
		},
		async linearVisibilityOn() {
			if (!this.entity.isLoaded) {
				// 初回表示
				await this.$store.dispatch("executeWithSpinner", async () => {
					try {
						if (this.entity.lineType === "arbitrary") {
							await this.loadArbitraryLine(this.entity);
						} else if (this.entity.lineType === "sima") {
							await this.loadSimaLine(this.entity);
						}
					} catch (e) {
						console.error(e);
					}
				});
			} else {
				// 二回目以降
				if (this.entity.lineType === "arbitrary") {
					window["viewer"].toggleArbitraryLineVisibility(
						this.entity.viewerLineId,
						true,
					);
				} else if (this.entity.lineType === "sima") {
					window["viewer"].setSIMAVisibility(this.entity.viewerLineId, true);
				}
				this.entity.isVisible = true;
			}
		},
		focus() {
			if (this.entity.lineType === "sima") {
				window["viewer"].zoomToSIMA(this.entity.viewerLineId);
			} else if (this.entity.lineType === "arbitrary") {
				window["viewer"].zoomToArbitrary(this.entity.viewerLineId);
			}
		},
	},
};
</script>

<style scoped>
.item-list {
	height: 28px;
	align-items: center
}
</style>
