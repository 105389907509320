<template>
    <!-- 親に設定されたv-bind, v-onをそのまま設定 -->
    <tooltip theme="info" v-bind="$attrs">
        <!-- 親に設定されたスロットをそのまま設定 -->
        <template v-for="(slot, name) in $slots" #[name]="data">
          <slot :name="name" v-bind="data"></slot>
        </template>
    </Tooltip>
</template>

<script>
import { Tooltip } from "floating-vue";
import "floating-vue/dist/style.css";

export default {
	components: {
		Tooltip,
	},
};
</script>

<style lang="sass">
.v-popper--theme-info .v-popper__inner
  font-size: 14px
  color: #ffffff
  background: #ffffff
  padding: 6px 10px
  border-radius: 4px
.v-popper--theme-info .v-popper__arrow-outer
  visibility: hidden
.v-popper--theme-info .v-popper__arrow-inner
  visibility: hidden
</style>