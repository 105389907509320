<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import axios from "axios";

const store = useStore();
const { t } = useI18n();

const props = defineProps({
	siteId: String,
});
const emit = defineEmits(["upload"]);

/** 対応拡張子 */
const allowedFileExtensions = ["gc3", "tp3", "dc", "cal"];

const allowedFileExtensionsStr = ref(
	allowedFileExtensions.map((extension) => "." + extension).join(", "),
);

function isAllowedExtension(fileName) {
	// ファイル名が許容された拡張子で終わるか確認する
	const pattern = new RegExp(`\\.(${allowedFileExtensions.join("|")})$`, "i");
	return pattern.test(fileName);
}

/** エラー表示 */
const errorMessage = ref("");

const resetError = () => {
	errorMessage.value = "";
};

/** ファイル変換処理 */
const selectedFile = ref(null);

const localization = ref({});

const convertToLocalizationData = async () => {
	if (!selectedFile.value) return;
	if (!isAllowedExtension(selectedFile.value.name)) {
		// 許容されていない拡張子の場合、変換処理を行わなずエラーを表示する
		errorMessage.value = t("INVALID_EXTENSION_ERROR");
		return;
	}

	// 変換処理のリクエスト
	const URL = `${import.meta.env.VITE_API_BASE}/gc3localization/${props.siteId}`;
	const fd = new FormData();
	console.log("selectedFile", selectedFile.value);
	fd.append("file", selectedFile.value);
	try {
		await store.dispatch("executeWithSpinner", async () => {
			const res = await axios.post(URL, fd);
			localization.value = res.data;
		});
	} catch (e) {
		console.error(e);
		store.commit("set_snackbar", {
			text: t("FAILED_TO_CONVERT_LOCALIZATION_DATA"),
			color: "rgba(153, 0, 0, 0.72)",
		});
		selectedFile.value = null;
	}
};

const handleFileUpdate = async (newSelectedFile) => {
	resetError();
	selectedFile.value = newSelectedFile ? newSelectedFile : null;
	await convertToLocalizationData();
};

/** 確認画面の処理 */
const showConfirm = computed(() => !!Object.keys(localization.value).length);

const upload = () => {
	emit("upload", true, localization.value);
};
</script>

<template>
    <!-- ファイル選択画面 -->
    <v-row v-if="!showConfirm">
        <v-col>
            <label class="file-select-label">{{ $t("LOCALIZATION_FILE_UPLOAD_LABEL", [allowedFileExtensionsStr]) }}</label>
            <p class="file-select-capture">{{ $t("LOCALIZATION_FILE_UPLOAD_CAPTION") }}</p>
            <!-- ファイル選択エリア -->
            <v-file-input class="file-select-area" :model-value="selectedFile" @update:modelValue= "handleFileUpdate " variant="outlined" :accept="allowedFileExtensionsStr"
                :prepend-icon="false" :clearable="false" single-line :error-messages="errorMessage">
                <!-- ファイル選択ボタン -->
                <template #prepend-inner>
                    <v-btn class="rounded-pill" color="primary" min-width="140px" height="35px">
                        {{ $t("SELECT_FILES") }}
                    </v-btn>
                </template>
                <!-- 入力ラベル -->
                <template #label>
                    <div class="d-inline-block ml-2 input-label">
                        <v-icon class="d-inline-block mr-2">mdi-upload</v-icon>
                        <span class="d-inline-block">{{ $t("DROP_FILES_HERE") }}</span>
                    </div>
                </template>
            </v-file-input>
        </v-col>
    </v-row>
    <!-- 確認画面 -->
    <v-row v-else>
        <v-col>
            <v-row>
                <v-col class="d-flex justify-center confirm-message">
                    {{ $t("APPLY_LOCALIZATION_CHANGES") }}<br><br>
                    rotation: {{ localization.rotation }}<br>
                    origin east: {{ localization.origin_easting }}<br>
                    rotation: {{ localization.rotation }}<br>
                    vertical offset: {{ localization.vertical_offset }}<br>
                    incline x: {{ localization.incline_x }}<br>
                    incline y: {{ localization.incline_y }}<br>
                    origin easting: {{ localization.origin_easting }}<br>
                    origin northing: {{ localization.origin_northing }}<br>
                    origin latitude: {{ localization.origin_latitude }}<br>
                    origin longitude: {{ localization.origin_longitude }}<br>
                    scale factor: {{ localization.scale_factor }}<br>
                    to meters: {{ localization.to_meters }}<br>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex justify-end">
                    <v-btn color="primary" rounded @click="upload"> {{ $t("Confirm") }} </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style lang="scss" scoped>
// ファイル選択画面
.file-select-label {
    font-size: 0.9rem;
}

.file-select-capture {
    word-wrap: break-all;
    font-size: 0.8rem;
    opacity: 0.6;
}

:deep(.file-select-area) {
    .input-label {
        color: rgba(151, 143, 143, 0.8);
    }
    // アウトラインのスタイル変更
    .v-field__outline {
        --border-style: 1px dashed rgba(255, 255, 255, 0.8);
        .v-field__outline__start {
            border-top: var(--border-style);
            border-bottom: var(--border-style);
            border-left: var(--border-style);
        }
        .v-field__outline__end{
            border-top: var(--border-style);
            border-bottom: var(--border-style);
            border-right: var(--border-style);
        }
    }
}

// 確認画面
.confirm-message {
    line-height: 1.4rem;
}
</style>