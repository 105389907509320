<template>
  <div>
    <v-card-text class="py-0">
      <v-btn size="36" variant="text" icon @click="toggleExpansion" class="ml-0">
        <v-icon> mdi-play {{ expansion ? "mdi-rotate-90" : "" }} </v-icon>
      </v-btn>
      <span>{{ $t("DESIGN") }}</span>
    </v-card-text>
    <v-expand-transition>
      <div v-show="expansion" class="list mx-3 mb-3" id="design_list">
        <v-card-text v-if="design_list.length == 0" class="pa-0 pl-10">
          {{ $t("NO_DATA") }}
        </v-card-text>
        <draggable handle=".handle" animation="553" v-model="designListDraggable" :item-key="(item) => item.getId()">
          <template #item="{ element }">
            <v-card-text class="pa-0 handle">
              <design-list-item v-if="element.getType() == 'design'" :entity="element"
                :draggable="true"></design-list-item>
              <line-list-item-in-design v-else-if="element.getType() == 'lineworks'" :entity="element"
                :draggable="true"></line-list-item-in-design>
            </v-card-text>
          </template>
        </draggable>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";
import DesignListItem from "./DesignListItem.vue";
import LineListItemInDesign from "./LineWorks/LineListItemInDesign.vue";
export default {
	components: {
		draggable,
		DesignListItem,
		LineListItemInDesign,
	},
	name: "DesignList",
	data() {
		return {
			expansion: false,
		};
	},
	computed: {
		...mapState(["design_list"]),
		designListDraggable: {
			get() {
				return this.design_list;
			},
			set(value) {
				this.set_design_list(value);
			},
		},
	},
	methods: {
		...mapMutations(["set_design_list"]),
		toggleExpansion() {
			this.expansion = !this.expansion;
		},
	},
};
</script>

<style lang="scss" scoped>
.list {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
</style>
