import axios from "axios";

const isNewVersion = () => {
	const url = `${import.meta.env.VITE_API_BASE}/version.json?t=${new Date().getTime()}`;
	axios.get(url).then((res) => {
		if (res.status === 200) {
			const version = res.data.version;
			const localVersion = localStorage.getItem("version");
			if (localVersion && localVersion != version) {
				localStorage.setItem("version", version);
				window.location.reload();
				return;
			} else {
				localStorage.setItem("version", version);
			}
		}
	});
};

export default {
	isNewVersion,
};
