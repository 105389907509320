<template>
    <v-card class="px-4 pt-2 pb-4">
        <v-card-title class="d-flex align-center justify-space-between">
            <label>
                {{ $t("LOCALIZATION_FILE") }}
            </label>
            <v-btn icon variant="flat" @click="$emit('close')">
                <v-icon> mdi-close </v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0 px-3 white-text">
            <v-container class="pa-0">
                <v-row class="d-block ma-0" style="width: 100%">
                    <v-col>
                        <!-- チェックボックス -->
                        <v-row>
                            <v-col cols="3" class="d-flex align-center mb-1 pa-0">
                                <v-checkbox color="primary" class="mt-0 checkbox-label-size" v-model="isPointCloudChecked" :label="$t('POINT_CLOUD')"
                                    hide-details>
                                </v-checkbox>
                            </v-col>
                            <v-col cols="3" class="d-flex align-center mb-1 pa-0">
                                <v-checkbox color="primary" class="mt-0 checkbox-label-size" v-model="isDesignChecked" :label="$t('DESIGN')"
                                    hide-details>
                                </v-checkbox>
                            </v-col>
                            <v-col cols="3" class="d-flex align-center mb-1 pa-0">
                                <v-checkbox color="primary" class="mt-0 checkbox-label-size" v-model="isLineworksChecked" :label="$t('LINEWORKS')"
                                    hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <!-- 点群データを選択 -->
                        <v-row v-if="isPointCloudChecked">
                            <v-col cols="12" class="mt-3 pa-0">
                                <span>
                                    <label>{{ $t("SELECT_POINT_CLOUD_DATA") }} ( las, csv, txt )</label>
                                    <v-select variant="outlined" :label="$t('ADD')" v-model="selectedPoints"
                                        density="compact"
                                        single-line
                                        bg-color="white"  :items="pointCloudList" item-title="name" return-object hide-details
                                        multiple>
                                        <template v-slot:selection="{ index }">
                                            <span class="text-black" v-if="index === 0">{{
                                                $t("ADD")
                                            }}</span>
                                        </template>
                                        <template v-slot:item="{ item, props }">
                                            <v-list-item v-bind="props"
                                                :disabled="isDisabledPointCloudSelectItem(item.raw.id)">
                                                <template v-slot:prepend="{ isActive }">
                                                    <v-checkbox-btn              color="primary" :model-value="isActive"></v-checkbox-btn>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </span>
                                <div v-if="isPointCloudChecked && selectedPoints.length">
                                    <div v-for="point in selectedPoints" :key="point.id" class="pt-2 d-flex justify-space-between"
                                        style="width: 95%">
                                        <span class="selected-list">{{ point.name }}</span>
                                        <close-button @click="deselectPoint(point.id)" size="20px"></close-button>
                                    </div>
                                </div>
                            </v-col>
                            <!-- 軸を選択 -->
                            <v-col v-show="isAxisEnabled " cols="12" class="mt-3 pa-0">
                                <span>
                                    <label>{{ $t("axis") }}</label>
                                    <v-select variant="outlined" v-model="axis" bg-color="white"
                                    density="compact"
                                    :items="axisList" hide-details>
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>
                        <!-- 設計データを選択 -->
                        <v-row v-if="isDesignChecked">
                            <v-col cols="12" class="mt-3 pa-0">
                                <span>
                                    <label>{{ $t("SELECT_DESIGN_DATA") }} ( xml, dxf )</label>
                                    <v-select variant="outlined" :label="$t('ADD')" v-model="selectedDesigns"
                                        single-line
                                        bg-color="white" density="compact" :items="designList" item-title="name" return-object hide-details
                                        multiple>
                                        <template v-slot:selection="{ index }">
                                            <span class="text-black" v-if="index === 0">{{
                                                $t("ADD")
                                            }}</span>
                                        </template>
                                    </v-select>
                                </span>
                                <div v-if="isDesignChecked && selectedDesigns.length">
                                    <div v-for="design in selectedDesigns" :key="design.id"
                                        class="pt-2 d-flex justify-space-between" style="width: 95%">
                                        <span class="selected-list">{{ design.name }}</span>
                                        <close-button @click="deselectDesign(design.id)" size="20px"></close-button>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- ラインワークスデータを選択 -->
                        <v-row v-if="isLineworksChecked">
                            <v-col cols="12" class="mt-3 pa-0">
                                <span>
                                    <label>{{ $t("SELECT_LINEWORKS_DATA") }}</label>
                                    <v-select variant="outlined" :label="$t('ADD')" v-model="selectedLineworks"
                                        single-line
                                        bg-color="white" density="compact" :items="designList" item-title="name" return-object hide-details
                                        multiple>
                                        <template v-slot:selection="{ index }">
                                            <span v-if="index === 0">{{
                                                $t("ADD")
                                            }}</span>
                                        </template>
                                    </v-select>
                                </span>
                                <div v-if="isLineworksChecked && selectedLineworks.length">
                                    <div v-for="lineworks in selectedLineworks" :key="lineworks.id"
                                        class="pt-2 d-flex justify-space-between" style="width: 95%">
                                        <span class="selected-list">{{ lineworks.name }}</span>
                                        <close-button @click="deselectLineworks(lineworks.id)" size="20px"></close-button>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- アップロードボタン -->
                        <v-row>
                            <v-col cols="4" class="mt-3 mb-2 pa-0">
                                <v-btn class="rounded-pill" color="primary" width="150px" height="35px" variant="flat"
                                    :disabled="isDisabledUploadBtn" @click="upload">
                                    {{ $t("UPLOAD") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import CloseButton from "@/components/common/CloseButton.vue";
import { mapMutations } from "vuex";
import axios from "axios";

export default {
	components: {
		CloseButton,
	},
	props: {
		isShow: Boolean,
	},
	data() {
		return {
			axisList: [
				"ENZ",
				"NEZ",
				"ENZRGB",
				"NEZRGB",
				"ENZiRGB",
				"NEZiRGB",
				"ENZRGBi",
				"NEZRGBi",
			],
			axis: "ENZ",
			selectedPoints: [],
			selectedDesigns: [],
			selectedLineworks: [],
			pointCloudBucketId: {
				survey: "",
				preconstruction: "",
			},
			surveyPointCloudList: [],
			preconstructionPointCloudList: [],
			designBucketId: "",
			designList: [],
			isPointCloudChecked: false,
			isDesignChecked: false,
			isLineworksChecked: false,
			date: "",
			time: "",
		};
	},
	computed: {
		/**
		 * ダウンロードボタンの活性制御
		 */
		isDisabledUploadBtn() {
			return (
				!this.selectedPoints.length &&
				!this.selectedDesigns.length &&
				!this.selectedLineworks.length
			);
		},
		/**
		 * 軸の可視制御
		 */
		isAxisEnabled() {
			if (!this.selectedPoints.length) {
				return false;
			}
			// 暫定対応で点群1件ずつしかアップロードできないため1件目だけチェックする
			// TODO: 恒久対応で複数点群のアップロードが可能になった場合選択された点群ごとに軸の要否のチェックを行うようにする
			const name = this.selectedPoints[0].name;
			// 拡張子がlasの場合は軸は非表示
			const AXIS_IGNORE_EXTENSIONS = ["las"];
			const extension = name.substring(name.lastIndexOf(".") + 1).toLowerCase();
			if (extension && AXIS_IGNORE_EXTENSIONS.includes(extension)) {
				return false;
			}
			return true;
		},
		pointCloudList() {
			// surveyとpreconstructionの点群リストを昇順で並べる
			const sortedPointCloudList = [
				...this.surveyPointCloudList,
				...this.preconstructionPointCloudList,
			].sort((a, b) => Number(a.created_at) - Number(b.created_at));
			return sortedPointCloudList;
		},
	},
	watch: {
		isShow(newValue) {
			if (newValue) {
				this.setItemList();
			}
		},
		isPointCloudChecked(newValue) {
			// チェックがオフになったら点群データの選択をクリア
			if (!newValue) this.selectedPoints = [];
		},
		isDesignChecked(newValue) {
			// チェックがオフになったら設計データの選択をクリア
			if (!newValue) this.selectedDesigns = [];
		},
		isLineworksChecked(newValue) {
			// チェックがオフになったらラインワークスデータの選択をクリア
			if (!newValue) this.selectedLineworks = [];
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		resetProperty() {
			(this.selectedPoints = []),
				(this.selectedDesigns = []),
				(this.selectedLineworks = []),
				(this.pointCloudBucketId = {
					survey: "",
					preconstruction: "",
				}),
				(this.surveyPointCloudList = []),
				(this.surveyPointCloudList = []),
				(this.designBucketId = ""),
				(this.designList = []),
				(this.isPointCloudChecked = false),
				(this.isDesignChecked = false),
				(this.isLineworksChecked = false),
				(this.date = ""),
				(this.time = "");
		},
		async getSurveyPointSCloudList() {
			const URL = `${import.meta.env.VITE_API_BASE}/assetLink/survey/${this.$route.query.siteId}`;
			const res = await axios.get(URL);
			this.pointCloudBucketId.survey = res.data.bucket_id;
			if (res.data.nodes) {
				this.surveyPointCloudList = res.data.nodes.map((node) => ({
					type: "survey",
					...node,
				}));
			}
		},
		async getPreconstructionPointSCloudList() {
			const URL = `${import.meta.env.VITE_API_BASE}/assetLink/preconstruction/${this.$route.query.siteId}`;
			const res = await axios.get(URL);
			this.pointCloudBucketId.preconstruction = res.data.bucket_id;
			if (res.data.nodes) {
				this.preconstructionPointCloudList = res.data.nodes.map((node) => ({
					type: "preconstruction",
					...node,
				}));
			}
		},
		async getDesignList() {
			const URL = `${import.meta.env.VITE_API_BASE}/assetLink/design/${this.$route.query.siteId}`;
			const res = await axios.get(URL);
			this.designBucketId = res.data.bucket_id;
			this.designList = res.data.nodes;
		},
		async pointCloudUpload(point) {
			const URL = `${import.meta.env.VITE_API_BASE}/assetLink/pointcloud/${this.$route.query.siteId}`;
			const data = {
				bucket_id: this.pointCloudBucketId[point.type],
				node_id: point.id,
				name: point.name,
				date: this.date,
				time: this.time,
			};
			if (this.isAxisEnabled) {
				data.axis = this.axis;
			}
			await axios.post(URL, data);
		},
		async designUpload(design) {
			const URL = `${import.meta.env.VITE_API_BASE}/assetLink/design/${this.$route.query.siteId}`;
			const data = {
				bucket_id: this.designBucketId,
				node_id: design.id,
				name: design.name,
				date: this.date,
				time: this.time,
			};
			await axios.post(URL, data);
		},
		async lineworksUpload(lineworks) {
			const URL = `${import.meta.env.VITE_API_BASE}/assetLink/lineworks/${this.$route.query.siteId}`;
			const data = {
				bucket_id: this.designBucketId,
				node_id: lineworks.id,
				name: lineworks.name,
			};
			await axios.post(URL, data);
		},
		deselectPoint(id) {
			this.selectedPoints = this.selectedPoints.filter(
				(item) => id !== item.id,
			);
		},
		deselectDesign(id) {
			this.selectedDesigns = this.selectedDesigns.filter(
				(item) => id !== item.id,
			);
		},
		deselectLineworks(id) {
			this.selectedLineworks = this.selectedLineworks.filter(
				(item) => id !== item.id,
			);
		},
		setUploadDateTime() {
			// 現在の日時を取得
			const now = new Date();
			// 日付を yyyy-mm-dd 形式でフォーマット
			const year = now.getFullYear();
			const month = (now.getMonth() + 1).toString().padStart(2, "0");
			const day = now.getDate().toString().padStart(2, "0");
			this.date = `${year}-${month}-${day}`;
			// 時刻を hh:mm:00 形式でフォーマット
			const hours = now.getHours().toString().padStart(2, "0");
			const minutes = now.getMinutes().toString().padStart(2, "0");
			this.time = `${hours}:${minutes}:00`;
		},
		upload() {
			this.setUploadDateTime();
			// 選択項目からAPIリクエストリストを作成
			const pointCloudUploadRequestList = this.selectedPoints.map((item) =>
				this.pointCloudUpload(item),
			);
			const designUploadRequestList = this.selectedDesigns.map((item) =>
				this.designUpload(item),
			);
			const lineworksUploadRequestList = this.selectedLineworks.map((item) =>
				this.lineworksUpload(item),
			);
			// リクエストを送信
			this.$store.commit("setLoading", true);
			Promise.allSettled([
				...pointCloudUploadRequestList,
				...designUploadRequestList,
				...lineworksUploadRequestList,
			])
				.then((results) => {
					const errorRequest = results.find(
						(result) => result.status == "rejected",
					);
					if (!errorRequest) {
						// エラーが無い場合正常終了のメッセージ
						this.set_snackbar({
							text: `${this.$t("UPLOAD")}${this.$t("REQUEST")} ${this.$t("successful")}`,
							color: "rgba(0, 153, 0, 0.72)",
						});
						this.refreshDataList();
						// 正常終了の場合は閉じる
						this.$emit("close");
					} else {
						// 1件でもエラーがある場合異常終了のメッセージ
						this.set_snackbar({
							text: `${this.$t("UPLOAD")} ${this.$t("failed")}`,
							color: "rgba(153, 0, 0, 0.72)",
						});
					}
				})
				.finally(() => {
					this.$store.commit("setLoading", false);
				});
		},
		setItemList() {
			this.getSurveyPointSCloudList();
			this.getPreconstructionPointSCloudList();
			this.getDesignList();
		},
		isDisabledPointCloudSelectItem(id) {
			// 点群ごとに軸を指定できないため、暫定対応で点群は1件しか選択できないようにする
			const isSelected = this.selectedPoints.find((item) => item.id === id);
			return !isSelected && this.selectedPoints.length;
		},
		refreshDataList() {
			const siteId = this.$route.query.siteId;
			if (this.selectedPoints.length) {
				this.$store.dispatch("get_point_cloud_list", siteId);
			}
			if (this.selectedDesigns.length || this.selectedLineworks.length) {
				this.$store.dispatch("get_design_list", siteId);
			}
		},
	},
	created() {
		this.setItemList();
	},
};
</script>

<style lang="sass" scoped>
:deep(.checkbox-label-size .v-label)
  font-size: 12px

.selected-list
  width:90%
  font-size: 14px

</style>