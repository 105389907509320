<template>
  <div class="line-work-name ml-2">
    <ListItemLabel v-if="!isRenamingMode" :text="entity?.lineName ?? ''" />
    <!-- ファイル名編集 -->
    <div class="line-work-name-input" v-else>
      <VeeValidateForm :initial-values="{ name: entity.lineName }" v-slot="{ meta }">
        <VeeValidateField name="name" :rules="nameRules" v-slot="{ field, errorMessage }">
          <ErrorTooltip placement="bottom" :shown="!meta.valid" :triggers="[]">
            <v-text-field v-model="name" v-bind="field" type="text" :error="!meta.valid" color="primary"
              class="ma-0 pa-0" hide-details="" variant="outlined" autofocus :loading="isLoading"
              @blur="blur(meta.valid)" @keydown.enter.prevent @keyup.enter="enter(meta.valid)"
              @keyup.escape="cancelUpdateFileName">
            </v-text-field>
            <template #popper>
              <span>{{ errorMessage }}</span>
            </template>
          </ErrorTooltip>
        </VeeValidateField>
      </VeeValidateForm>
    </div>
  </div>
</template>

<script>
import {
	Form as VeeValidateForm,
	Field as VeeValidateField,
} from "vee-validate";
import ErrorTooltip from "@/components/common/ErrorTooltip";
import ListItemLabel from "@/components/Project/ListItemLabel.vue";
import { mapMutations } from "vuex";

export default {
	name: "LineWorksName",
	components: {
		VeeValidateForm,
		VeeValidateField,
		ErrorTooltip,
		ListItemLabel,
	},
	props: {
		entity: Object,
		isRenamingMode: Boolean,
	},
	data() {
		return {
			name: "",
			hasUpdatedError: false,
			nameRules: [
				(value) => {
					if (value) return true;
					return this.$t("REQUIRED_ERROR");
				},
				(value) => {
					if (value?.length <= 100) return true;
					return this.$t("MAX_LENGTH_ERROR");
				},
			],
		};
	},
	watch: {
		name() {
			// 変更があればサーバーエラー解除
			if (this.hasUpdatedError) this.hasUpdatedError = false;
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		async blur(valid) {
			// 前回のリクエストが終わっていない場合はリクエストを送らない（多重リクエスト防止）
			if (this.isLoading) return;

			// 前回のリクエストでエラーが発生していた場合はフォーカスアウト時にリクエスト送信しない
			if (this.hasUpdatedError) {
				this.cancelUpdateFileName();
				return;
			}

			// 前回のリクエストが終わっていない場合はリクエストを送らない
			if (this.isLoading) return;

			// バリデーションエラーがある場合編集キャンセル
			if (!valid) {
				this.cancelUpdateFileName();
				return;
			}
			// ファイル名に変更がなければリクエストを送らない
			if (this.name === this.entity.lineName) {
				this.endNameEditing();
				return;
			}
			// スピナー表示開始
			await this.$store.dispatch("executeWithSpinner", async () => {
				// リクエスト成功の場合は編集モード終了、失敗の場合は変更を戻す
				const status = await this.updateFileName();
				if (status) {
					this.endNameEditing();
				} else {
					this.cancelUpdateFileName();
				}
			});
		},
		async enter(valid) {
			// 前回のリクエストが終わっていない場合はリクエストを送らない（多重リクエスト防止）
			if (this.isLoading) return;

			// バリデーションエラーがある場合Enterキーを押しても確定させない
			if (!valid) {
				return;
			}
			// ファイル名に変更がなければリクエストを送らない
			if (this.name === this.entity.lineName) {
				this.endNameEditing();
				return;
			}
			// スピナー表示開始
			await this.$store.dispatch("executeWithSpinner", async () => {
				// リクエスト成功の場合は編集モード終了
				const status = await this.updateFileName();
				if (status) {
					this.endNameEditing();
				}
			});
		},
		// ファイル名変更
		async updateFileName() {
			const successMessage = `${this.$t("UPDATE_DATA")} ${this.$t("successful")}`;
			const errorMessage = `${this.$t("UPDATE_DATA")} ${this.$t("failed")}`;
			const oldName = this.entity.lineName;
			try {
				// 更新リクエスト
				this.entity.lineName = this.name;
				if (this.entity.lineType === "arbitrary") {
					await this.$store.dispatch("arbitraryLine/updateName", {
						arbitraryLine: this.entity,
					});
				} else if (this.entity.lineType === "sima") {
					await this.$store.dispatch("simaLine/updateName", {
						simaLine: this.entity,
					});
				}

				this.set_snackbar({
					text: successMessage,
					color: "rgba(0, 153, 0, 0.72)",
				});
				return this.entity.lineName;
			} catch {
				this.entity.lineName = oldName;
				this.hasUpdatedError = true;
				this.set_snackbar({
					text: errorMessage,
					color: "rgba(153, 0, 0, 0.72)",
				});
				return "";
			}
		},
		// ファイル名変更キャンセル
		cancelUpdateFileName() {
			this.name = this.entity.lineName;
			this.endNameEditing();
		},
		// ファイル名編集終了
		endNameEditing() {
			this.hasUpdatedError = false;
			this.entity.lineName = this.name;
			this.$emit("end-rename");
		},
	},
	created() {
		this.name = this.entity.lineName;
	},
};
</script>

<style scoped>
.line-work-name {
  flex: 1;
  min-width: 0;
}

.line-work-name :deep(input.v-field__input) {
  padding: 0 6px;
  min-height: 0px;
  font-size: 14px;
}

.line-work-name-input {
  width: 100%;
}
</style>
