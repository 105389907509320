import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import { i18n } from "./i18n";
import router from "./router";
import VueCookies from "vue-cookies";
import "./assets/style_custom.css";
import "@/assets/icons/style.css";
import axios from "axios";

if (import.meta.env.DEV) {
	// ローカル実行時にはクロスオリジンの場合でもcookie情報を送れるようにする。同一オリジンでの場合は自動的にcookieが付与される
	axios.defaults.withCredentials = true;
}

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vuetify);
app.use(i18n);
app.use(VueCookies);

app.mount("#app");
