// https://javascript.info/indexeddb

/**
 * schema for the database
 * {
 * user_id: string,
 * site_id: string,
 * tracker_type: TrackerType,
 * updated_at: string,
 * data: any
 * }
 * combined index for user_id, site_id, tracker_type is used to track the data for a specific user, site and tracker_type
 * tracker_type = ["pointcloud", "design"];
 * In future if we need to add more tracker_type like camera, and others
 * Data will persist until the user clears the data or the browser clears the data
 */

const TrackerType = Object.freeze({
	POINTCLOUD: "pointcloud",
	DESIGN: "design",
});

const DB_NAME = "SCDesign3D"; // name of the database
const DB_VERSION = 1; // increase this number when the schema changes
const DB_STORE_NAME = "tracker"; // like a table or collection in a database
const TrackerIndex = "tracker_idx"; // index to track the data for a specific user, site and tracker_type
let db;

/**
 *
 * @returns {Promise<IDBDatabase>}
 */
function setUpDB() {
	return new Promise((resolve, reject) => {
		if (!("indexedDB" in window)) {
			reject("IndexedDB not supported");
			return;
		}
		const req = indexedDB.open(DB_NAME, DB_VERSION);

		req.onsuccess = (evt) => {
			db = evt.currentTarget.result;
			// this event triggers when the version of database and the version of the schema don't match
			db.onversionchange = () => {
				db.close();
				reject("Database is outdated, please reload the page.");
			};
			resolve(db);
		};

		req.onerror = (evt) => {
			reject(evt.target.error);
		};

		// this event triggers when the database is created for the first time or the version is updated
		req.onupgradeneeded = (evt) => {
			db = evt.target.result;
			const store = db.createObjectStore(DB_STORE_NAME, {
				autoIncrement: true,
			});
			store.createIndex(TrackerIndex, ["user_id", "site_id", "tracker_type"], {
				unique: false,
			});
			store.transaction.oncomplete = (event) => {
				resolve(db);
			};
		};

		req.onblocked = () => {
			// this event shouldn't trigger if we handle onversionchange correctly
			// it means that there's another open connection to the same database
			// and it wasn't closed after db.onversionchange triggered for it
			reject("Outdated Connection detected, please reload the page.");
		};
	});
}

/**
 *
 * @returns {Promise<void>}
 */
function closeDB() {
	return new Promise((resolve, reject) => {
		if (!db) {
			reject("Database is not open");
			return;
		}
		db.close();
		const deleteRequest = indexedDB.deleteDatabase(DB_NAME);
		deleteRequest.onsuccess = () => {
			db = undefined;
			resolve(db);
		};
		deleteRequest.onerror = () => {};
	});
}

/**
 * @param {string} storeName
 * @param {string} mode either "readonly" or "readwrite"
 */
function getObjectStore(storeName, mode) {
	if (!db) {
		throw new Error("Database is not open");
	}
	const tx = db.transaction(storeName, mode);
	return tx.objectStore(storeName);
}

/**
 *
 * @returns {Promise<void>}
 */
function clearObjectStore() {
	return new Promise((resolve, reject) => {
		const store = getObjectStore(DB_STORE_NAME, "readwrite");
		const req = store.clear();
		req.onsuccess = (evt) => {
			resolve(evt.target.result);
		};
		req.onerror = (evt) => {
			reject(evt.target.error);
		};
	});
}

/**
 *
 * @param {TrackerType} trackerType
 * @param {string} userId
 * @param {string} siteId
 * @param {any} data
 * @returns
 */
function add(trackerType, userId, siteId, data) {
	return new Promise((resolve, reject) => {
		const store = getObjectStore(DB_STORE_NAME, "readwrite");
		const req = store.add({
			tracker_type: trackerType,
			user_id: userId,
			site_id: siteId,
			data,
			updated_at: new Date().toISOString(),
		});
		req.onsuccess = (evt) => {
			resolve(evt.target.result);
		};
		req.onerror = (evt) => {
			reject(evt.target.error);
		};
	});
}

/**
 *
 * @param {string} userId
 * @param {string} siteId
 * @param {TrackerType} trackerType
 * @param {any} data
 * @returns
 */
function getKeyByData(userId, siteId, trackerType, data) {
	return new Promise((resolve, reject) => {
		const store = getObjectStore(DB_STORE_NAME, "readwrite");
		const tracker_idx = store.index(TrackerIndex);
		const req = tracker_idx.getAllKeys([userId, siteId, trackerType]);
		req.onsuccess = async (evt) => {
			const keys = evt.target.result;
			for (const key of keys) {
				const keyData = await getDataByKey(key);
				if (keyData === data) {
					resolve(key);
				}
			}
			resolve(null);
		};
		req.onerror = (evt) => {
			reject(evt.target.error);
		};
	});
}

/**
 *
 * @param {number} key
 * @returns
 */
function getDataByKey(key) {
	return new Promise((resolve, reject) => {
		const store = getObjectStore(DB_STORE_NAME, "readwrite");
		const req = store.get(key);
		req.onsuccess = (evt) => {
			const data = evt.target.result;
			resolve(data);
		};
		req.onerror = (evt) => {
			reject(evt.target.error);
		};
	});
}

/**
 *
 * @param {number} key
 * @returns
 */
function deleteDataByKey(key) {
	return new Promise((resolve, reject) => {
		const store = getObjectStore(DB_STORE_NAME, "readwrite");
		const req = store.delete(key);
		req.onsuccess = (evt) => {
			resolve(evt.target.result);
		};
		req.onerror = (evt) => {
			reject(evt.target.error);
		};
	});
}

/**
 *
 * @param {TrackerType} trackerType
 * @param {string} userId
 * @param {string} siteId
 * @param {any} data
 * @returns
 */
async function remove(trackerType, userId, siteId, data) {
	return new Promise((resolve, reject) => {
		const store = getObjectStore(DB_STORE_NAME, "readwrite");
		const tracker_idx = store.index(TrackerIndex);
		const req = tracker_idx.getAllKeys([userId, siteId, trackerType]);
		req.onsuccess = async (evt) => {
			const keys = evt.target.result;
			const deletedKeys = [];
			for (const key of keys) {
				const keyData = await getDataByKey(key);
				if (keyData.data === data) {
					await deleteDataByKey(key);
					deletedKeys.push(key);
				}
			}
			resolve(deletedKeys);
		};
		req.onerror = (evt) => {
			reject(evt.target.error);
		};
	});
}

/**
 *
 * @param {string} userId
 * @param {string} siteId
 * @param {TrackerType} trackerType
 * @returns
 */
async function getAll(userId, siteId, trackerType) {
	return new Promise((resolve, reject) => {
		const store = getObjectStore(DB_STORE_NAME, "readwrite");
		const tracker_idx = store.index(TrackerIndex);
		const req = tracker_idx.getAll([userId, siteId, trackerType]);
		req.onsuccess = (evt) => {
			const data = evt.target.result;
			resolve(data);
		};
		req.onerror = () => {
			reject("error getting data");
		};
	});
}

/**
 *
 * @param {string} userId
 * @param {string} siteId
 * @param {string} pointCloudId
 * @returns
 */
async function addPointCloud(userId, siteId, pointCloudId) {
	const pointCloudsIds = await getPointClouds(userId, siteId);
	if (pointCloudsIds.includes(pointCloudId)) {
		return getKeyByData(userId, siteId, TrackerType.POINTCLOUD, pointCloudId);
	}
	return await add(TrackerType.POINTCLOUD, userId, siteId, pointCloudId);
}

/**
 *
 * @param {string} userId
 * @param {string} siteId
 * @param {string} pointCloudId
 * @returns
 */
async function deletePointCloud(userId, siteId, pointCloudId) {
	return await remove(TrackerType.POINTCLOUD, userId, siteId, pointCloudId);
}

/**
 *
 * @param {string} userId
 * @param {string} siteId
 * @returns {Promise<string[]>}
 */
async function getPointClouds(userId, siteId) {
	const resp = await getAll(userId, siteId, TrackerType.POINTCLOUD);
	return resp.map((r) => r.data);
}

/**
 *
 * @param {string} userId
 * @param {string} siteId
 * @param {string} designId
 * @returns
 */
async function addDesign(userId, siteId, designId) {
	const designIds = await getDesigns(userId, siteId);
	if (designIds.includes(designId)) {
		return getKeyByData(userId, siteId, TrackerType.DESIGN, designId);
	}
	return await add(TrackerType.DESIGN, userId, siteId, designId);
}

/**
 *
 * @param {string} userId
 * @param {string} siteId
 * @param {string} designId
 * @returns
 */
async function deleteDesign(userId, siteId, designId) {
	return await remove(TrackerType.DESIGN, userId, siteId, designId);
}

/**
 *
 * @param {string} userId
 * @param {string} siteId
 * @returns {Promise<string[]>}
 */
async function getDesigns(userId, siteId) {
	const resp = await getAll(userId, siteId, TrackerType.DESIGN);
	return resp.map((r) => r.data);
}

export default {
	setUpDB: setUpDB,
	clearObjectStore: clearObjectStore,
	addPointCloud: addPointCloud,
	deletePointCloud: deletePointCloud,
	getPointClouds: getPointClouds,
	addDesign: addDesign,
	deleteDesign: deleteDesign,
	getDesigns: getDesigns,
};
