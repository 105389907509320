<!-- 計測データリストのコンポーネント -->
<template>
    <div>
        <v-card-text class="py-0 pt-3">
            <v-btn
              size="36"
              variant="text"
              icon
              @click="toggleExpansion"
              class="ml-0"
            >
                <v-icon>
                    mdi-play  {{ expansion ? "mdi-rotate-90" : "" }}
                </v-icon>
            </v-btn>
            <span>{{ $t("LINEAR_DATA") }}</span>
        </v-card-text>
        <v-expand-transition>
          <div
            v-show="expansion"
            style="scroll-behavior: smooth;"
            class="ml-4 mr-3 mb-3"
          >
              <v-card-text v-if="measurementList.length == 0" class="pa-0 pl-10">
                  {{ $t("NO_DATA") }}
              </v-card-text>
              <v-card-text
                  v-for="(entity) in measurementList"
                  :key="entity.measurementId"
                  class="pa-0 mb-3"
              >
                  <measurement-list-item 
                      :entity="entity" 
                  ></measurement-list-item>
              </v-card-text>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
import MeasurementListItem from "./MeasurementListItem.vue";

export default {
	components: {
		MeasurementListItem,
	},
	name: "MeasurementList",
	data() {
		return {
			expansion: false,
		};
	},
	computed: {
		measurementList() {
			return this.$store.getters["measurement/measurementList"];
		},
	},
	methods: {
		toggleExpansion() {
			this.expansion = !this.expansion;
		},
	},
};
</script>