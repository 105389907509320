<!-- 道路・床掘中心線から任意線形を作成するため、対象の道路・床掘を選択するダイアログ -->
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import * as constants from "@/constant.js";
import useArbitraryLines from "@/composables/useArbitraryLines.js";
import temporaryRoadIcon from "@/assets/icons/customIcons/temporary-road-icon.svg";
import diggingIcon from "@/assets/icons/customIcons/digging-icon.svg";

/** 状態定義 */
const store = useStore();
const selectedObjects = ref([]);
/** 摺り付き先(点群/設計/平場) */
const rubblingDestinations = computed(() => {
	const pointCloudsAndDesigns = store.getters.pointCloudAndDesignList
		.filter((asset) => asset.asset_id)
		.map((asset) => ({
			id: asset.asset_id,
			name: asset.design_name ?? asset.point_cloud_name,
		}));
	const flatGrounds = store.getters.objects
		.filter((obj) => obj.id && obj.type === constants.objectType.FLAT)
		.map((obj) => ({ id: obj.id, name: obj.name }));
	return pointCloudsAndDesigns.concat(flatGrounds);
});
/**
 * テーブル表示する仮説道路/床掘
 * ビューワ上で表示状態にある(仮設道路/床掘を擦り付け先の表示状態に依らずに)表示する
 */
const items = computed(() => {
	return store.getters.objects.filter((obj) => {
		if (obj.json && obj.isShow) {
			const json = JSON.parse(obj.json);
			// 道路は擦り付け先が点群のみ
			if (obj.type === constants.objectType.ROAD) {
				// HACK: 型が統一されていないので厳密等価演算子にしない
				return rubblingDestinations.value.find(
					(dest) => dest.id == json.associatePointCloudId,
				);
			}
			// 床掘は擦り付け先が点群or設計or平場
			if (obj.type === constants.objectType.TRENCH) {
				// HACK: 型が統一されていないので厳密等価演算子にしない
				return rubblingDestinations.value.find(
					(dest) => dest.id == json.trenchSettings.commonSetting.destination.id,
				);
			}
		}
		return false;
	});
});
/** テーブルヘッダー  */
const { t } = useI18n();
const headers = [
	{ title: `${t("ROAD")} / ${t("TRENCH")}`, key: "type" },
	{ title: t("NAME"), key: "name" },
];
const isRoad = (type) => type === constants.objectType.ROAD;

/** Event */
const emit = defineEmits(["close"]);
const { createArbitraryLineFromObject } = useArbitraryLines();
const submit = async () =>
	await createArbitraryLineFromObject(selectedObjects.value, emit("close"));
const cancel = () => {
	emit("close");
};
</script>

<template>
    <v-card class="container px-4 pt-2 pb-4">
        <v-card-title class="d-flex align-center justify-space-between">
            <label>
                {{ $t("CREATE_LINEWORK_FROM_OBJECT") }}
            </label>
            <v-btn icon variant="flat" @click="$emit('close')">
                <v-icon> mdi-close </v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="body">
            <v-data-table class="table" v-model="selectedObjects" :headers="headers" :items="items"
                :item-value="item => item.id" :item-selectable="item => item.isShow" items-per-page="-1" return-object
                show-select>
                <!-- typeに応じてアイコンを表示する -->
                <template v-slot:item.type="{ value }">
                    <img v-if="isRoad(value)" :src="temporaryRoadIcon" alt="Temporary Road Icon" />
                    <img v-else :src="diggingIcon" alt="Trench Icon" />
                </template>
                <!-- Hide footer -->
                <template #bottom></template>
            </v-data-table>
            <span class="note">{{ $t("CREATE_LINEWORK_FROM_OBJECT_NOTE") }}</span>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="rounded-pill btn" color="red" variant="flat" @click="cancel()">
                {{ $t("CANCEL") }}
            </v-btn>
            <v-btn class="rounded-pill btn" color="primary" variant="flat" @click="submit()"
                :disabled="!selectedObjects.length">
                {{ $t("OK") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<style lang="scss" scoped>
.container {
    overflow: hidden;

    .body {
        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;
            min-height: 0;

            /* disabled のチェックボックスをより薄くする */
            :deep(.v-selection-control--disabled) {
                opacity: 0.2;
            }
        }

        .note {
            text-align: right;
            margin-top: 24px;
        }
    }

    .btn {
        width: 100px;
    }
}
</style>