<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title> {{ $t("Addtemporaryroad") }} </v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-row class="ma-0">
          <v-col cols="6">
            <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
              <tbody>
                <tr>
                  <td style="width: 50%">
                    {{ $t("Roadname") }}
                  </td>
                  <td>
                    <v-text-field variant="underlined" hide-details density="compact" v-model="property.name" :rules="[rules.validName]" autofocus />
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="6">
            <!-- 道路・摺り付け -->
            {{ $t("Roadparameter") }}
            <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
              <tbody>
                <!-- 道路幅_左 -->
                <tr>
                  <td style="width: 50%">
                    {{ $t("Widthofroad") }} {{ $t("Left") }}
                  </td>
                  <td>
                    <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                      @update:model-value="updateRoadSettings()" v-model.number="property.settings.commonSettings.leftWidth"
                      :rules="[rules.orLess(99.99), rules.orMore(0), rules.decimalLength(2), rules.numberFormat]" />
                  </td>
                </tr>
                <!-- 道路幅_右 -->
                <tr>
                  <td>
                    <span style="opacity: 0">{{ $t("Widthofroad") }}</span>
                    {{ $t("Right") }}
                  </td>
                  <td>
                    <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                      @update:model-value="updateRoadSettings()" v-model.number="property.settings.commonSettings.rightWidth"
                      :rules="[rules.orLess(99.99), rules.orMore(0), rules.decimalLength(2), rules.numberFormat]" />
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("Setsuperelevation") }}
                  </td>
                  <td>
                    <v-select variant="underlined" v-model="property.settings.commonSettings.isSuperElevation" :items="slopeTypes"
                      item-title="label" item-value="value" hide-details density="compact" class="ma-0"
                      v-on:update:model-value="updateRoadSettings()"></v-select>
                  </td>
                </tr>
                <!-- 片勾配 -->
                <tr v-if="property.settings.commonSettings.isSuperElevation">
                  <td>
                    <div>
                      {{ $t("Superelevation") }}
                    </div>
                  </td>
                  <td>
                    <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(roadParameterSlopeUnit.superElevation)"
                      :is-editable="true" :slope-value="property.settings.commonSettings.leftSlope" :step="0.01"
                      @update:model-value="updateRoadSettings" v-model.number="property.settings.commonSettings.leftSlope"
                      :max-value="999.99" :min-value="-999.99" :decimal-length="2"
                      :un-valid-zero="roadParameterSlopeUnit.superElevation === 'percent'"></SlopeSettingInput>
                  </td>
                </tr>
                <!-- 横断勾配 -->
                <tr v-else>
                  <td>
                    <div>
                      {{ $t("Crossslope") }}
                    </div>
                  </td>
                  <td>
                    <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(roadParameterSlopeUnit.crossSlope)"
                      :is-editable="true" :slope-value="property.settings.commonSettings.leftSlope" :step="0.01"
                      @update:model-value="updateRoadSettings" v-model.number="property.settings.commonSettings.leftSlope"
                      :max-value="roadParameterSlopeUnit.crossSlope === 'percent' ? 9999.99 : 999.99"
                      :min-value="roadParameterSlopeUnit.crossSlope === 'percent' ? 0 : 0.01" :decimal-length="2"
                      :un-valid-zero="roadParameterSlopeUnit.crossSlope === 'percent'"></SlopeSettingInput>
                  </td>
                </tr>
                <!-- 摺り付けピッチ -->
                <tr>
                  <td>
                    {{ $t("InterpolationPitch") }}
                  </td>
                  <td>
                    <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                      @update:model-value="updateRoadSettings()" v-model.number="property.settings.commonSettings.interpolationPitch"
                      :rules="[
                        rules.orLess(99.99),
                        rules.orMore(0.01),
                        rules.decimalLength(2),
                        rules.numberFormat
                      ]" model-value="5" />
                  </td>
                </tr>
                <!-- 路面色 -->
                <tr>
                  <td>
                    {{ $t("Roadsurfacecolor") }}
                  </td>
                  <td>
                      <v-menu>
                        <template v-slot:activator="{ props }">
                          <v-avatar :color="property.settings.commonSettings.surfaceColor" size="20px" v-bind="props">
                          </v-avatar>
                        </template>
                        <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                          v-model="property.settings.commonSettings.surfaceColor"
                          @update:model-value="updateRoadMaterial()"></v-color-picker>
                      </v-menu>
                  </td>
                </tr>
                <!-- 縦断勾配指定 -->
                <tr>
                    <td>
                    {{ $t("ENABLE_VERTICAL_SLOPE_VALUE") }}
                    </td>
                    <td>
                    <v-checkbox color="primary" hide-details density="compact" class="ma-0"
                        v-model="isSlopeValueEnabled"></v-checkbox>
                    </td>
                </tr>
                <!-- 縦断勾配 -->
                <tr v-show="isSlopeValueEnabled">
                  <td>
                    {{ $t("Longitudinal_Slope_Value") }}
                  </td>
                  <td>
                    <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(roadParameterSlopeUnit.longitudinalSlope)"
                      :is-editable="true" :slope-value="slopeValue" :step="longitudinalSlope.step" @update:model-value="updateRoadSettings"
                      v-model.number="slopeValue" :max-value="longitudinalSlope.maxValue" :min-value="longitudinalSlope.minValue" :decimal-length="longitudinalSlope.decimalLength"
                      :un-valid-zero=true></SlopeSettingInput>
                  </td>
                </tr>
                <!-- 参照基準点 -->
                <tr v-show="isSlopeValueEnabled">
                  <td>
                    {{ $t("Slope_Reference_Point") }}
                  </td>
                  <td>
                    <v-select variant="underlined" v-model="referencePointIndex" :items="longitudinalReferencePoints" item-title="label"
                      item-value="value" hide-details density="compact" class="ma-0" v-on:update:model-value="updateRoadSettings()"></v-select>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col>
            <!-- 切土設定 -->
            <v-row class="ma-0">
              <v-col class="py-0">
                {{ $t("Cutsetting") }}
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col>
                <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
                  <tbody>
                    <!-- 法面高さ -->
                    <tr>
                      <td style="width: 50%">
                        {{ $t("Slopeheight") }}
                      </td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m" :rules="cutSlopeCondition ? [] :
                            [
                              rules.required,
                              rules.orLess(99.99),
                              rules.orMore(0.01),
                              rules.decimalLength(2),
                              rules.numberFormat
                            ]" :disabled="cutSlopeCondition" @update:model-value="updateRoadSettings()"
                          v-model.number="property.settings.cutSettings.roadHeight" />
                      </td>
                    </tr>
                    <!-- 法面勾配 -->
                    <tr>
                      <td>
                        {{ $t("Slopegradient") }}
                      </td>
                      <td>

                        <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                          :is-editable="!property.settings.cutSettings.bLeftRightSettings"
                          :slope-value="property.settings.cutSettings.moundSlope" :step="0.01" @update:model-value="updateRoadSettings"
                          v-model.number="property.settings.cutSettings.moundSlope"
                          :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                          :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>


                      </td>
                    </tr>
                    <!-- 小段幅 -->
                    <tr>
                      <td>
                        {{ $t("Bermwidth") }}
                      </td>
                      <td>

                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                          :disabled="cutSlopeCondition" :rules="cutSlopeCondition ? [] :
                              [
                                rules.orLess(99.99),
                                rules.orMore(0.01),
                                rules.decimalLength(2),
                                rules.numberFormat
                              ]" @update:model-value="updateRoadSettings()"
                          v-model.number="property.settings.cutSettings.shelfWidth" />
                      </td>
                    </tr>
                    <!-- 小段勾配 -->
                    <tr>
                      <td>
                        {{ $t("Bermgradient") }}
                      </td>
                      <td>


                        <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                          :is-editable="!cutSlopeCondition" :slope-value="property.settings.cutSettings.shelfSlope"
                          :step="0.01" @update:model-value="updateRoadSettings"
                          v-model.number="property.settings.cutSettings.shelfSlope"
                          :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                          :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01" :decimal-length="2"
                          :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'"></SlopeSettingInput>

                      </td>
                    </tr>
                    <!-- 左右設定 -->
                    <tr>
                      <td>
                        {{ $t("LeftRightSettings") }}
                      </td>
                      <td>
                        <v-checkbox color="primary" hide-details density="compact" class="ma-0" @click="updateRoadSettings()"
                          v-model="property.settings.cutSettings.bLeftRightSettings"></v-checkbox>
                      </td>
                    </tr>
                    <!-- 小段あり -->
                    <tr>
                      <td>
                        {{ $t("Thereisberm") }}
                      </td>
                      <td>
                        <v-checkbox color="primary" hide-details density="compact" class="ma-0" @click="updateRoadSettings()"
                          v-model="property.settings.cutSettings.generateShelf"></v-checkbox>
                      </td>
                    </tr>
                    <!-- 道路色 -->
                    <tr>
                      <td>
                        {{ $t("Roadcolor") }}
                      </td>
                      <td>
                          <v-menu>
                            <template v-slot:activator="{ props }">
                              <v-avatar :color="property.settings.cutSettings.color" size="20px" v-bind="props">
                              </v-avatar>
                            </template>
                            <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                              v-model="property.settings.cutSettings.color"
                              @update:model-value="updateRoadMaterial()"></v-color-picker>
                          </v-menu>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
                <!-- 左右設定 -->
                <v-expansion-panels>
                  <v-expansion-panel style="background-color: rgba(41, 41, 47, 0)">
                    <v-expansion-panel-title class="px-4 py-0">
                      {{ $t("Leftrightsetting") }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="pt-2">
                      <v-row class="ma-0">
                        <!-- 左切土設定 -->
                        <v-col class="pa-0">
                          {{ $t("Leftcutsetting") }}
                          <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)" class="pa-0">
                            <tbody>
                              <!-- 法面高さ -->
                              <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                    :disabled="cutLeftRightCondition" :rules="cutLeftRightCondition ? [] :
                                        [
                                          rules.required,
                                          rules.orLess(99.99),
                                          rules.orMore(0.01),
                                          rules.decimalLength(2),
                                          rules.numberFormat
                                        ]" @update:model-value="updateRoadSettings()"
                                    v-model.number="property.settings.cutSettings.leftSlopeHeight" />
                                </td>
                              </tr>
                              <!-- 法面勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                    :is-editable="property.settings.cutSettings.bLeftRightSettings"
                                    :slope-value="property.settings.cutSettings.leftMoundSlope" :step="0.01"
                                    @update:model-value="updateRoadSettings()"
                                    v-model.number="property.settings.cutSettings.leftMoundSlope"
                                    :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>


                                </td>
                              </tr>
                              <!-- 小段幅 -->
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                    :disabled="cutLeftRightCondition" :rules="cutLeftRightCondition ? [] :
                                        [
                                          rules.required,
                                          rules.orLess(99.99),
                                          rules.orMore(0.01),
                                          rules.decimalLength(2),
                                          rules.numberFormat
                                        ]" @update:model-value="updateRoadSettings()"
                                    v-model.number="property.settings.cutSettings.leftShelfWidth" />
                                </td>
                              </tr>
                              <!-- 小段勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                    :is-editable="!cutLeftRightCondition"
                                    :slope-value="property.settings.cutSettings.leftShelfSlope" :step="0.01"
                                    @update:model-value="updateRoadSettings"
                                    v-model.number="property.settings.cutSettings.leftShelfSlope"
                                    :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                    :decimal-length="2" :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                  </SlopeSettingInput>

                                </td>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                        <!-- 右切土設定 -->
                        <v-col class="pa-0">
                          {{ $t("Rightcutsetting") }}
                          <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
                            <tbody>
                              <!-- 法面高さ -->
                              <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                    :disabled="cutLeftRightCondition" :rules="cutLeftRightCondition ? [] :
                                        [
                                          rules.required,
                                          rules.orLess(99.99),
                                          rules.orMore(0.01),
                                          rules.decimalLength(2),
                                          rules.numberFormat
                                        ]" @update:model-value="updateRoadSettings()"
                                    v-model.number="property.settings.cutSettings.rightSlopeHeight" />
                                </td>
                              </tr>
                              <!-- 法面勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                    :is-editable="property.settings.cutSettings.bLeftRightSettings"
                                    :slope-value="property.settings.cutSettings.rightMoundSlope" :step="0.01"
                                    @update:model-value="updateRoadSettings"
                                    v-model.number="property.settings.cutSettings.rightMoundSlope"
                                    :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>

                                </td>
                              </tr>
                              <!-- 小段幅 -->
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                    :disabled="cutLeftRightCondition" :rules="cutLeftRightCondition ? [] :
                                        [
                                          rules.required,
                                          rules.orLess(99.99),
                                          rules.orMore(0.01),
                                          rules.decimalLength(2),
                                          rules.numberFormat
                                        ]" @update:model-value="updateRoadSettings()"
                                    v-model.number="property.settings.cutSettings.rightShelfWidth" />
                                </td>
                              </tr>
                              <!-- 小段勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                    :is-editable="!cutLeftRightCondition"
                                    :slope-value="property.settings.cutSettings.rightShelfSlope" :step="0.01"
                                    @update:model-value="updateRoadSettings"
                                    v-model.number="property.settings.cutSettings.rightShelfSlope"
                                    :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                    :decimal-length="2" :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                  </SlopeSettingInput>

                                </td>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <!-- 盛土設定 -->
            <v-row class="ma-0">
              <v-col class="py-0">
                {{ $t("Fillsetting") }}
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col>
                <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
                  <tbody>
                    <!-- 法面高さ -->
                    <tr>
                      <td style="width: 50%">
                        {{ $t("Slopeheight") }}
                      </td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                          :disabled="embSlopeCondition" :rules="embSlopeCondition ? [] :
                              [
                                rules.required,
                                rules.orLess(99.99),
                                rules.orMore(0.01),
                                rules.decimalLength(2),
                                rules.numberFormat
                              ]" @update:model-value="updateRoadSettings()"
                          v-model.number="property.settings.embarkmentSettings.roadHeight" />
                      </td>
                    </tr>
                    <!-- 法面勾配 -->
                    <tr>
                      <td>
                        {{ $t("Slopegradient") }}
                      </td>
                      <td>

                        <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                          :is-editable="!property.settings.embarkmentSettings.bLeftRightSettings"
                          :slope-value="property.settings.embarkmentSettings.moundSlope" :step="0.01"
                          @update:model-value="updateRoadSettings" v-model.number="property.settings.embarkmentSettings.moundSlope"
                          :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                          :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>


                      </td>
                    </tr>
                    <!-- 小段幅 -->
                    <tr>
                      <td>
                        {{ $t("Bermwidth") }}
                      </td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                          :disabled="embSlopeCondition" :rules="embSlopeCondition ? [] :
                              [
                                rules.required,
                                rules.orLess(99.99),
                                rules.orMore(0.01),
                                rules.decimalLength(2),
                                rules.numberFormat
                              ]" @update:model-value="updateRoadSettings()"
                          v-model.number="property.settings.embarkmentSettings.shelfWidth" />
                      </td>
                    </tr>
                    <!-- 小段勾配 -->
                    <tr>
                      <td>
                        {{ $t("Bermgradient") }}
                      </td>
                      <td>

                        <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                          :is-editable="!embSlopeCondition" :slope-value="property.settings.embarkmentSettings.shelfSlope"
                          :step="0.01" @update:model-value="updateRoadSettings"
                          v-model.number="property.settings.embarkmentSettings.shelfSlope"
                          :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                          :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01" :decimal-length="2"
                          :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'"></SlopeSettingInput>


                      </td>
                    </tr>
                    <!-- 左右設定 -->
                    <tr>
                      <td>
                        {{ $t("LeftRightSettings") }}
                      </td>
                      <td>
                        <v-checkbox color="primary" hide-details density="compact" class="ma-0" @click="updateRoadSettings()"
                          v-model="property.settings.embarkmentSettings.bLeftRightSettings"></v-checkbox>
                      </td>
                    </tr>
                    <!-- 小段あり -->
                    <tr>
                      <td>
                        {{ $t("Thereisberm") }}
                      </td>
                      <td>
                        <v-checkbox color="primary" hide-details density="compact" class="ma-0" @click="updateRoadSettings()"
                          v-model="property.settings.embarkmentSettings.generateShelf"></v-checkbox>
                      </td>
                    </tr>
                    <!-- 道路色 -->
                    <tr>
                      <td>
                        {{ $t("Roadcolor") }}
                      </td>
                      <td>
                          <v-menu>
                            <template v-slot:activator="{ props }">
                              <v-avatar :color="property.settings.embarkmentSettings.color" size="20px" v-bind="props">
                              </v-avatar>
                            </template>
                            <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                              v-model="property.settings.embarkmentSettings.color"
                              @update:model-value="updateRoadMaterial()"></v-color-picker>
                          </v-menu>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
                <!-- 左右設定 -->
                <v-expansion-panels>
                  <v-expansion-panel style="background-color: rgba(41, 41, 47, 0)">
                    <v-expansion-panel-title class="px-4 py-0">
                      {{ $t("Leftrightsetting") }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="pt-2">
                      <v-row class="ma-0">
                        <!-- 左盛土設定 -->
                        <v-col class="pa-0">
                          {{ $t("Leftfillsetting") }}
                          <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)" class="pa-0">
                            <tbody>
                              <!-- 法面高さ -->
                              <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                    @update:model-value="updateRoadSettings()" :disabled="embLeftRightCondition" :rules="embLeftRightCondition ? [] :
                                        [
                                          rules.required,
                                          rules.orLess(99.99),
                                          rules.orMore(0.01),
                                          rules.decimalLength(2),
                                          rules.numberFormat
                                        ]" v-model.number="property.settings.embarkmentSettings.leftSlopeHeight" />
                                </td>
                              </tr>
                              <!-- 法面勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                    :is-editable="property.settings.embarkmentSettings.bLeftRightSettings"
                                    :slope-value="property.settings.embarkmentSettings.leftMoundSlope" :step="0.01"
                                    @update:model-value="updateRoadSettings"
                                    v-model.number="property.settings.embarkmentSettings.leftMoundSlope"
                                    :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>

                                </td>
                              </tr>
                              <!-- 小段幅 -->
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                    :disabled="embLeftRightCondition" :rules="embLeftRightCondition ? [] :
                                        [
                                          rules.required,
                                          rules.orLess(99.99),
                                          rules.orMore(0.01),
                                          rules.decimalLength(2),
                                          rules.numberFormat
                                        ]" @update:model-value="updateRoadSettings()"
                                    v-model.number="property.settings.embarkmentSettings.leftShelfWidth" />
                                </td>
                              </tr>
                              <!-- 小段勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                    :is-editable="!embLeftRightCondition"
                                    :slope-value="property.settings.embarkmentSettings.leftShelfSlope" :step="0.01"
                                    @update:model-value="updateRoadSettings"
                                    v-model.number="property.settings.embarkmentSettings.leftShelfSlope"
                                    :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                    :decimal-length="2" :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'">
                                  </SlopeSettingInput>

                                </td>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                        <!-- 右盛土設定 -->
                        <v-col class="pa-0">
                          {{ $t("Rightfillsetting") }}
                          <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
                            <tbody>
                              <!-- 法面高さ -->
                              <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                    @update:model-value="updateRoadSettings()" :disabled="embLeftRightCondition" :rules="embLeftRightCondition ? [] :
                                        [
                                          rules.required,
                                          rules.orLess(99.99),
                                          rules.orMore(0.01),
                                          rules.decimalLength(2),
                                          rules.numberFormat
                                        ]" v-model.number="property.settings.embarkmentSettings.rightSlopeHeight" />
                                </td>
                              </tr>
                              <!-- 法面勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                    :is-editable="property.settings.embarkmentSettings.bLeftRightSettings"
                                    :slope-value="property.settings.embarkmentSettings.rightMoundSlope" :step="0.01"
                                    @update:model-value="updateRoadSettings"
                                    v-model.number="property.settings.embarkmentSettings.rightMoundSlope"
                                    :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>

                                </td>
                              </tr>
                              <!-- 小段幅 -->
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                    :disabled="embLeftRightCondition" :rules="embLeftRightCondition ? [] :
                                        [
                                          rules.required,
                                          rules.orLess(99.99),
                                          rules.orMore(0.01),
                                          rules.decimalLength(2),
                                          rules.numberFormat
                                        ]" @update:model-value="updateRoadSettings()"
                                    v-model.number="property.settings.embarkmentSettings.rightShelfWidth" />
                                </td>
                              </tr>
                              <!-- 小段勾配 -->
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>

                                  <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                    :is-editable="!embLeftRightCondition"
                                    :slope-value="property.settings.embarkmentSettings.rightShelfSlope" :step="0.01"
                                    @update:model-value="updateRoadSettings"
                                    v-model.number="property.settings.embarkmentSettings.rightShelfSlope"
                                    :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                    :decimal-length="2" :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'">
                                  </SlopeSettingInput>


                                </td>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col>
            <v-card-actions class="justify-end">
              <v-btn variant="flat" @click="closeDialog" color="red" :disabled="isRoadAdded">
                {{ $t("CANCEL") }}
              </v-btn>
              <v-btn variant="flat" @click="addRoad()" color="primary" :disabled="!isValid || addRoadDisabled || isRoadAdded">
                {{ $t("ADD") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import SlopeSettingInput from "./SlopeSettingInput.vue";
import { UnitTypes } from "../../constant";
import rules from "@/utils/validation-rules.js";
import {
	convertCoordinatesToPointCloudCrs,
	convertCoordinateToPointCloudCrs,
	updateRoadCommonSettings,
} from "./../../utils/cesium-common";
import { longitudinalSlope } from "@/config/numberConfig";

export default {
	name: "PropertyDialogRoad",
	components: { SlopeSettingInput },
	props: {
		id: String,
		type: Number,
	},
	async beforeMount() {
		this.property = JSON.parse(
			JSON.stringify(this.$store.getters.defaultRoadProperty),
		);
		const preSettingsRoadJSON = window["viewer"].getRoadJSON(this.id);
		const roadLength = window["viewer"].getLengthOfRoad(this.id);
		const projectedLength = window[
			"viewer"
		].getCorrespondingProjectedDistFromStart(this.id, roadLength);
		const preSettingsRoadObj = JSON.parse(preSettingsRoadJSON);
		const controlPoints = preSettingsRoadObj.controlPoints;

		const [firstPoint, lastPoint] = await convertCoordinatesToPointCloudCrs([
			{
				x: controlPoints[0].point[0],
				y: controlPoints[0].point[1],
				z: controlPoints[0].point[2],
			},
			{
				x: controlPoints[controlPoints.length - 1].point[0],
				y: controlPoints[controlPoints.length - 1].point[1],
				z: controlPoints[controlPoints.length - 1].point[2],
			},
		]);

		const heightDifference = lastPoint.z - firstPoint.z;

		this.defaultSlopeValue =
			heightDifference === 0 ? 0 : projectedLength / heightDifference;
		this.slopeValue = this.defaultSlopeValue;
	},
	mounted() {
		// this.convertRatioSlopesToPercentSlopes();
	},
	data: () => ({
		isValid: false,
		property: null,
		slopeValue: 0,
		defaultSlopeValue: 0,
		referencePointIndex: 0,
		isRoadAdded: false,
		isSlopeValueEnabled: false,
		longitudinalSlope,
		rules: {
			...rules,
			required: (value) => !!value || "*",
			validName: (v) => !/[~@#$^*()+=[\]{}|\\,?:<>!'"/;&₹€`%]/.test(v),
		},
	}),
	computed: {
		// Disabling condition for cut setting's - shelfSlope, shelfWidth
		cutSlopeCondition() {
			return (
				this.property.settings.cutSettings.bLeftRightSettings ||
				!this.property.settings.cutSettings.generateShelf
			);
		},
		// Disabling condition for embankment setting's - shelfSlope, shelfWidth
		embSlopeCondition() {
			return (
				this.property.settings.embarkmentSettings.bLeftRightSettings ||
				!this.property.settings.embarkmentSettings.generateShelf
			);
		},
		// Disabling condition for cut setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
		cutLeftRightCondition() {
			return !(
				this.property.settings.cutSettings.bLeftRightSettings &&
				this.property.settings.cutSettings.generateShelf
			);
		},
		// Disabling condition for embankment setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
		embLeftRightCondition() {
			return !(
				this.property.settings.embarkmentSettings.bLeftRightSettings &&
				this.property.settings.embarkmentSettings.generateShelf
			);
		},
		roadParameterSlopeUnit() {
			return this.$store.state.roadParameterSlopeUnit;
		},
		cutSettingSlopeUnit() {
			return this.$store.state.cutSettingSlopeUnit;
		},
		fillSettingSlopeUnit() {
			return this.$store.state.fillSettingSlopeUnit;
		},
		addRoadDisabled: function () {
			return (
				this.property.name === null ||
				this.property.name === "" ||
				/^\s*$/.test(this.property.name)
			);
		},
		slopeTypes() {
			return [
				{
					label: this.$t("Crossslope"),
					value: false,
				},
				{
					label: this.$t("Superelevation"),
					value: true,
				},
			];
		},
		controlPointsLength() {
			return window["viewer"].getRoadPointLength(this.id);
		},
		longitudinalReferencePoints() {
			return [
				{
					label: `0（${this.$t("START_POINT")}）`,
					value: 0,
				},
				{
					label: `${this.controlPointsLength - 1}（${this.$t("END_POINT")}）`,
					value: this.controlPointsLength - 1,
				},
			];
		},
	},
	watch: {
		isSlopeValueEnabled(newValue) {
			if (!newValue) {
				// チェックオフがオフになったら縦断勾配、参照基準点をデフォルト値に戻す
				this.slopeValue = this.defaultSlopeValue;
				this.referencePointIndex = 0;
			}
		},
	},
	methods: {
		resetProperty() {
			this.property = JSON.parse(
				JSON.stringify(this.$store.getters.defaultRoadProperty),
			);
			// this.convertRatioSlopesToPercentSlopes();
			this.isRoadAdded = false;
		},
		getSuffix(unitType) {
			const unit = UnitTypes.find((type) => type.value === unitType);
			return unit.symbol;
		},
		closeDialog() {
			window["viewer"].deleteRoad(this.id);
			this.$emit("close", false);
			this.property = JSON.parse(
				JSON.stringify(this.$store.getters.defaultRoadProperty),
			);
		},
		async addRoad() {
			this.isRoadAdded = true;

			this.property.id = this.id;
			this.property.cid = this.id;
			this.property.type = this.type;
			// const d = JSON.parse(JSON.stringify(this.property.settings));
			try {
				// ローディング開始
				await this.$store.dispatch("executeWithSpinner", async () => {
					try {
						const preSettingsRoadJSON = window["viewer"].getRoadJSON(this.id);
						const roadLength = window["viewer"].getLengthOfRoad(this.id);
						const projectedLength = window[
							"viewer"
						].getCorrespondingProjectedDistFromStart(this.id, roadLength);
						const preSettingsRoadObj = JSON.parse(preSettingsRoadJSON);
						const controlPoints = preSettingsRoadObj.controlPoints;

						const referencePoint = await convertCoordinateToPointCloudCrs({
							x: controlPoints[this.referencePointIndex].point[0],
							y: controlPoints[this.referencePointIndex].point[1],
							z: controlPoints[this.referencePointIndex].point[2],
						});

						const heightDiff =
							this.slopeValue !== 0 ? projectedLength / this.slopeValue : 0; //slope value is 1/n

						const settingsJSON = this.property.json || preSettingsRoadJSON;
						const jsonSettingsObj = JSON.parse(settingsJSON);
						jsonSettingsObj.slopeBreaks = [
							{
								percentage: 0,
								height:
									this.referencePointIndex === 0
										? referencePoint.z
										: referencePoint.z + heightDiff,
							},
							{
								percentage: 1,
								height:
									this.referencePointIndex === 0
										? referencePoint.z + heightDiff
										: referencePoint.z,
							},
						];

						this.property.json = JSON.stringify(jsonSettingsObj);

						const roadData = updateRoadCommonSettings(this.id, this.property);
						this.property.json = JSON.stringify(roadData);

						window["viewer"].deleteRoad(this.id);
						const pointCloudId =
							this.$store.getters.activePointCloud?.getAssetIdNum();
						this.property.id = await window["viewer"].loadRoadJSON(
							this.property.json,
							pointCloudId,
						);
						const objConnectionJSON =
							window["viewer"].getConnectionsInSiteJSON();
						this.property.info.length = window["viewer"].getLengthOfRoad(
							this.id,
						);
						this.property.info.height = window["viewer"].getRoadHeightInfo(
							this.id,
						);
						this.property.info.slope = window["viewer"].getRoadSlopeInfo(
							this.id,
						);

						console.log({
							siteId: this.$route.query.siteId,
							objJSON: this.property.json,
							objProps: this.property,
							objConnectionJSON: objConnectionJSON,
						});
						const res = await axios.post(
							`${import.meta.env.VITE_API_BASE}/obj/${this.$route.query.siteId}`,
							{
								siteId: this.$route.query.siteId,
								objJSON: this.property.json,
								objProps: this.property,
								objConnectionJSON: objConnectionJSON,
							},
							{
								auth: this.$store.state.authObject,
							},
						);
						// オブジェクトリストの更新
						await this.$store.dispatch("get_obj", res.data.json_name);
						// 追加した仮設道路を可視化する
						const addedObj = this.$store.state.objects.find(
							(entity) => entity.cid === this.id,
						);
						if (addedObj) {
							addedObj.isLoaded = true;
							addedObj.isShow = true;
						}
					} catch (e) {
						console.error(e);
						window["viewer"].deleteRoad(this.id);
						this.$store.commit("set_snackbar", {
							text: this.$t("ROAD_SAVE_FAILURE"),
							color: "rgba(153, 0, 0, 0.72)",
						});
					}
				});
			} finally {
				this.$emit("close", false);
			}
		},
		isNum(val) {
			return typeof val == "number";
		},
		updateRoadSettings() {
			if (this.id === null) return;

			// this.updateSlopes(this.property),
			//common settings
			this.property.settings.commonSettings.leftWidth = this.isNum(
				this.property.settings.commonSettings.leftWidth,
			)
				? this.property.settings.commonSettings.leftWidth
				: 2.5;
			this.property.settings.commonSettings.rightWidth = this.isNum(
				this.property.settings.commonSettings.rightWidth,
			)
				? this.property.settings.commonSettings.rightWidth
				: 2.5;
			this.property.settings.commonSettings.leftSlope = this.isNum(
				this.property.settings.commonSettings.leftSlope,
			)
				? this.property.settings.commonSettings.leftSlope
				: 50;
			this.property.settings.commonSettings.rightSlope =
				this.property.settings.commonSettings.leftSlope;
			this.property.settings.commonSettings.interpolationPitch =
				this.isNum(this.property.settings.commonSettings.interpolationPitch) &&
				this.property.settings.commonSettings.interpolationPitch != 0
					? this.property.settings.commonSettings.interpolationPitch
					: 5;

			this.property.settings.commonSettings.rightSlope = this.property.settings
				.commonSettings.isSuperElevation
				? -1 * this.property.settings.commonSettings.rightSlope
				: this.property.settings.commonSettings.rightSlope;

			//embarkment settings
			this.property.settings.embarkmentSettings.roadHeight = this.isNum(
				this.property.settings.embarkmentSettings.roadHeight,
			)
				? this.property.settings.embarkmentSettings.roadHeight
				: 5;
			this.property.settings.embarkmentSettings.moundSlope = this.isNum(
				this.property.settings.embarkmentSettings.moundSlope,
			)
				? this.property.settings.embarkmentSettings.moundSlope
				: 1.5;

			this.property.settings.embarkmentSettings.shelfWidth = this.isNum(
				this.property.settings.embarkmentSettings.shelfWidth,
			)
				? this.property.settings.embarkmentSettings.shelfWidth
				: 1.5;
			this.property.settings.embarkmentSettings.shelfSlope = this.isNum(
				this.property.settings.embarkmentSettings.shelfSlope,
			)
				? this.property.settings.embarkmentSettings.shelfSlope
				: 100;

			this.property.settings.embarkmentSettings.leftSlopeHeight = this.isNum(
				this.property.settings.embarkmentSettings.leftSlopeHeight,
			)
				? this.property.settings.embarkmentSettings.leftSlopeHeight
				: 5;
			this.property.settings.embarkmentSettings.leftMoundSlope = this.isNum(
				this.property.settings.embarkmentSettings.leftMoundSlope,
			)
				? this.property.settings.embarkmentSettings.leftMoundSlope
				: 1.5;
			this.property.settings.embarkmentSettings.rightSlopeHeight = this.isNum(
				this.property.settings.embarkmentSettings.rightSlopeHeight,
			)
				? this.property.settings.embarkmentSettings.rightSlopeHeight
				: 5;
			this.property.settings.embarkmentSettings.rightMoundSlope = this.isNum(
				this.property.settings.embarkmentSettings.rightMoundSlope,
			)
				? this.property.settings.embarkmentSettings.rightMoundSlope
				: 1.5;

			this.property.settings.embarkmentSettings.leftShelfWidth = this.isNum(
				this.property.settings.embarkmentSettings.leftShelfWidth,
			)
				? this.property.settings.embarkmentSettings.leftShelfWidth
				: 1.5;
			this.property.settings.embarkmentSettings.leftShelfSlope = this.isNum(
				this.property.settings.embarkmentSettings.leftShelfSlope,
			)
				? this.property.settings.embarkmentSettings.leftShelfSlope
				: 100;
			this.property.settings.embarkmentSettings.rightShelfWidth = this.isNum(
				this.property.settings.embarkmentSettings.rightShelfWidth,
			)
				? this.property.settings.embarkmentSettings.rightShelfWidth
				: 1.5;
			this.property.settings.embarkmentSettings.rightShelfSlope = this.isNum(
				this.property.settings.embarkmentSettings.rightShelfSlope,
			)
				? this.property.settings.embarkmentSettings.rightShelfSlope
				: 100;

			//cut settings
			this.property.settings.cutSettings.roadHeight = this.isNum(
				this.property.settings.cutSettings.roadHeight,
			)
				? this.property.settings.cutSettings.roadHeight
				: 5;
			this.property.settings.cutSettings.moundSlope = this.isNum(
				this.property.settings.cutSettings.moundSlope,
			)
				? this.property.settings.cutSettings.moundSlope
				: 1;

			this.property.settings.cutSettings.shelfWidth = this.isNum(
				this.property.settings.cutSettings.shelfWidth,
			)
				? this.property.settings.cutSettings.shelfWidth
				: 1.5;
			this.property.settings.cutSettings.shelfSlope = this.isNum(
				this.property.settings.cutSettings.shelfSlope,
			)
				? this.property.settings.cutSettings.shelfSlope
				: 100;

			this.property.settings.cutSettings.leftSlopeHeight = this.isNum(
				this.property.settings.cutSettings.leftSlopeHeight,
			)
				? this.property.settings.cutSettings.leftSlopeHeight
				: 5;
			this.property.settings.cutSettings.leftMoundSlope = this.isNum(
				this.property.settings.cutSettings.leftMoundSlope,
			)
				? this.property.settings.cutSettings.leftMoundSlope
				: 1;
			this.property.settings.cutSettings.rightSlopeHeight = this.isNum(
				this.property.settings.cutSettings.rightSlopeHeight,
			)
				? this.property.settings.cutSettings.rightSlopeHeight
				: 5;
			this.property.settings.cutSettings.rightMoundSlope = this.isNum(
				this.property.settings.cutSettings.rightMoundSlope,
			)
				? this.property.settings.cutSettings.rightMoundSlope
				: 1;

			this.property.settings.cutSettings.leftShelfWidth = this.isNum(
				this.property.settings.cutSettings.leftShelfWidth,
			)
				? this.property.settings.cutSettings.leftShelfWidth
				: 1.5;
			this.property.settings.cutSettings.leftShelfSlope = this.isNum(
				this.property.settings.cutSettings.leftShelfSlope,
			)
				? this.property.settings.cutSettings.leftShelfSlope
				: 100;
			this.property.settings.cutSettings.rightShelfWidth = this.isNum(
				this.property.settings.cutSettings.rightShelfWidth,
			)
				? this.property.settings.cutSettings.rightShelfWidth
				: 1.5;
			this.property.settings.cutSettings.rightShelfSlope = this.isNum(
				this.property.settings.cutSettings.rightShelfSlope,
			)
				? this.property.settings.cutSettings.rightShelfSlope
				: 100;

			// const d = JSON.parse(JSON.stringify(this.property.settings));
			// window["viewer"].updateRoadSettings(this.id, d);
		},
		updateRoadMaterial() {
			window["viewer"].updateRoadMaterialSettings(
				this.id,
				{
					surfaceColor: this.property.settings.commonSettings.surfaceColor,
				},
				{
					color: this.property.settings.embarkmentSettings.color,
					transparency: this.property.transparency,
				},
				{
					color: this.property.settings.cutSettings.color,
					transparency: this.property.transparency,
				},
			);
		},
	},
};
</script>