<!-- 仮設道路・平場名のコンポーネント -->
<template>
  <div class="obj-name ml-2">
    <!-- ファイル名表示 -->
    <ListItemLabel v-if="!isRenamingMode" :text="obj?.name ?? ''" />
    <!-- ファイル名編集 -->
    <div class="obj-name-input" v-else>
      <VeeValidateForm :initial-values="{ name: obj.name }" v-slot="{ meta }">
        <VeeValidateField name="name" :rules="nameRules" v-slot="{ field, errorMessage }">
          <ErrorTooltip placement="bottom" :shown="!meta.valid" :triggers="[]">
            <v-text-field v-model="name" v-bind="field" type="text" :error="!meta.valid" color="primary"
              class="ma-0 pa-0" hide-details="" variant="outlined" autofocus style="font-size: 14px"
              :loading="isLoading" @blur="blur(meta.valid)" @keydown.enter.prevent @keyup.enter="enter(meta.valid)"
              @keyup.escape="cancelUpdateFileName">
            </v-text-field>
            <template #popper>
              <span>{{ errorMessage }}</span>
            </template>
          </ErrorTooltip>
        </VeeValidateField>
      </VeeValidateForm>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
	Form as VeeValidateForm,
	Field as VeeValidateField,
} from "vee-validate";
import * as lockUtils from "@/utils/lock-utils";
import { mapMutations } from "vuex";
import objUpdateMixin from "@/mixins/objUpdateMixin.js";
import ErrorTooltip from "@/components/common/ErrorTooltip";
import ListItemLabel from "@/components/Project/ListItemLabel.vue";

export default {
	mixins: [objUpdateMixin],
	components: {
		VeeValidateForm,
		VeeValidateField,
		ErrorTooltip,
		ListItemLabel,
	},
	props: {
		objId: String,
		isRenamingMode: Boolean,
	},
	data() {
		return {
			name: "", // オリジナルの名前（obj.name）とは別に変更後の名前を保持する
			hasUpdatedError: false, // 前回のAPIリクエストのエラー有無
			nameRules: [
				(value) => {
					if (value) return true;
					return this.$t("REQUIRED_ERROR");
				},
				(value) => {
					if (value?.length <= 100) return true;
					return this.$t("MAX_LENGTH_ERROR");
				},
			],
		};
	},
	computed: {
		isLoading: {
			get() {
				return this.$store.state.isLoading;
			},
			set(value) {
				this.$store.dispatch("updateIsLoading", value);
			},
		},
	},
	watch: {
		name() {
			// 変更があればサーバーエラー解除
			if (this.hasUpdatedError) this.hasUpdatedError = false;
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		async blur(valid) {
			// 前回のリクエストが終わっていない場合はリクエストを送らない（多重リクエスト防止）
			if (this.isLoading) return;

			// 前回のリクエストでエラーが発生していた場合はフォーカスアウト時にリクエスト送信しない
			if (this.hasUpdatedError) {
				this.cancelUpdateFileName();
				return;
			}
			// バリデーションエラーがある場合編集キャンセル
			if (!valid) {
				this.cancelUpdateFileName();
				return;
			}
			// ファイル名に変更がなければリクエストを送らない
			if (this.name === this.obj.name) {
				this.endNameEditing();
				return;
			}
			// スピナー表示開始
			await this.$store.dispatch("executeWithSpinner", async () => {
				// 編集中でない場合ロックを取得する。失敗した場合処理終了
				if (!this.obj.edit) {
					const lockAcquired = await lockUtils.lock(
						this.$route.query.siteId,
						this.obj.cid,
					);
					if (!lockAcquired) {
						this.cancelUpdateFileName();
						return;
					}
				}

				// リクエスト成功の場合は編集モード終了、失敗の場合は変更を戻す
				const status = await this.updateFileName();
				if (status) {
					this.endNameEditing();
				} else {
					this.cancelUpdateFileName();
				}
				// ロックを解放する
				if (!this.obj.edit) {
					lockUtils.releaseLock(this.obj.cid);
				}
			});
		},
		async enter(valid) {
			// 前回のリクエストが終わっていない場合はリクエストを送らない（多重リクエスト防止）
			if (this.isLoading) return;

			// バリデーションエラーがある場合Enterキーを押しても確定させない
			if (!valid) {
				return;
			}
			// ファイル名に変更がなければリクエストを送らない
			if (this.name === this.obj.name) {
				this.endNameEditing();
				return;
			}

			// スピナー表示開始
			await this.$store.dispatch("executeWithSpinner", async () => {
				// 編集中でない場合ロックを取得する。失敗した場合処理終了
				if (!this.obj.edit) {
					const lockAcquired = await lockUtils.lock(
						this.$route.query.siteId,
						this.obj.cid,
					);
					if (!lockAcquired) {
						// フォーカスアウト（blur）時にリクエストを送らないようにエラーフラグを更新
						this.hasUpdatedError = true;
						return;
					}
				}

				// リクエスト成功の場合は編集モード終了
				const status = await this.updateFileName();
				if (status) {
					this.endNameEditing();
				}
				// ロックを解放する
				if (!this.obj.edit) {
					lockUtils.releaseLock(this.obj.cid);
				}
			});
		},
		// ファイル名変更
		async updateFileName() {
			const body = {
				siteId: this.$route.query.siteId,
				objJSON: this.obj.json,
				objProps: { ...this.obj, name: this.name },
			};
			const status = await this.request(body);
			return status;
		},
		// ファイル名変更キャンセル
		cancelUpdateFileName() {
			this.name = this.obj.name;
			this.endNameEditing();
		},
		// ファイル名編集終了
		endNameEditing() {
			this.hasUpdatedError = false;
			this.obj.name = this.name;
			this.$emit("end-rename");
		},
		async request(body) {
			const successMessage = `${this.$t("UPDATE_DATA")} ${this.$t("successful")}`;
			const errorMessage = `${this.$t("UPDATE_DATA")} ${this.$t("failed")}`;
			try {
				this.isLoading = true;
				// 更新リクエスト
				const res = await axios.put(
					`${import.meta.env.VITE_API_BASE}/obj`,
					body,
				);
				if (res.status === 200) {
					// オブジェクトリストの更新
					await this.$store.dispatch("get_obj", this.obj.id);
					this.set_snackbar({
						text: successMessage,
						color: "rgba(0, 153, 0, 0.72)",
					});
					return res.status;
				} else {
					// 想定外のステータスコードが返却された場合エラーをスロー
					throw new Error(errorMessage);
				}
			} catch {
				this.hasUpdatedError = true;
				this.set_snackbar({
					text: errorMessage,
					color: "rgba(153, 0, 0, 0.72)",
				});
				return "";
			}
		},
	},
	created() {
		this.name = this.obj?.name;
	},
};
</script>

<style lang="scss" scoped>
.obj-name {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;

  :deep(input.v-field__input) {
    padding: 0 6px;
    min-height: 0px;
  }
}

.obj-name-input {
  width: 100%;
}
</style>