import axios from "axios";

export async function addLineWorks(entity) {
	if (entity.getLineType() === "dxf") {
		const json = await fetchDXF(entity.getId());
		await window["viewer"].addDXFFromJSON(entity.getId(), json);
	}
}

async function fetchDXF(dxfId) {
	const res = await axios.get(
		`${import.meta.env.VITE_API_BASE}/lineworks/polylines/${dxfId}`,
	);
	return res.data;
}

export function setLineWorksVisibility(entity, visible) {
	if (entity.getLineType() === "dxf") {
		window["viewer"].setDXFVisibility(entity.getId(), visible);
	}
}
