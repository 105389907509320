<template>
  <v-list density="compact">
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForTrenchEdit" :key="index"
      @click="onCMClickForTrenchEdit($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import * as constants from "@/constant.js";

export default {
	props: {
		pathId: String,
		pointId: String,
		showRemoveOption: Boolean,
	},
	data: function () {
		return {
			itemsForTrenchEdit: [
				{ id: 1, title: "Create Arc" },
				{ id: 2, title: "ADD" },
				...(this.showRemoveOption ? [{ id: 3, title: "Remove" }] : []),
				{ id: 5, title: "Update" },
				{ id: 6, title: "Finishdrawing" },
				{ id: 7, title: "Undo" },
				{ id: 8, title: "Redo" },
				...(import.meta.env
					.VITE_ENABLE_SEGMENT_SETTING_CONTEXT_MENU_FOR_TRENCH === "true"
					? [{ id: 9, title: "SegmentSetting" }]
					: []),
			],
		};
	},
	watch: {
		showRemoveOption: function (showRemoveOption) {
			this.itemsForTrenchEdit = [
				{ id: 1, title: "Create Arc" },
				{ id: 2, title: "ADD" },
				{ id: 3, title: "Remove" },
				{ id: 5, title: "Update" },
				{ id: 6, title: "Finishdrawing" },
				{ id: 7, title: "Undo" },
				{ id: 8, title: "Redo" },
				...(import.meta.env.VITE_ENABLE_SEGMENT_SETTING_CONTEXT_MENU === "true"
					? [{ id: 9, title: "SegmentSetting" }]
					: []),
			];

			if (!showRemoveOption) {
				const itemIndex = this.itemsForTrenchEdit.findIndex(
					(item) => item.id === 3,
				);
				if (itemIndex > -1) {
					this.itemsForTrenchEdit.splice(itemIndex, 1);
				}
			}
		},
	},
	methods: {
		onCMClickForTrenchEdit(e, id) {
			e.preventDefault();
			const numberOfPathPoints = window["viewer"].getTrenchPointLength(
				this.pathId,
			);
			const clickedIndex = window["viewer"].getControlPointIndexById(
				this.pathId,
				this.pointId,
			);
			switch (id) {
				case 1:
					window["viewer"].updateBendToCircleForTrench(
						this.pathId,
						this.pointId,
					);
					break;
				case 2:
					window["viewer"].addControlPointOnTrench(this.pathId, this.pointId);
					window.dispatchEvent(
						new CustomEvent("addPointEvent", {
							detail: {
								id: this.pathId,
								entityUnderEdit: constants.objectType.TRENCH,
								pointIndex: clickedIndex,
							},
						}),
					);
					break;
				case 3:
					window["viewer"].removeControlPointOnTrench(this.pointId);
					window.dispatchEvent(
						new CustomEvent("deletePointEvent", {
							detail: {
								id: this.pathId,
								entityUnderEdit: constants.objectType.TRENCH,
								pointIndex: clickedIndex,
							},
						}),
					);
					break;
				case 5:
					window.dispatchEvent(
						new CustomEvent("openPropertyDialog", {
							detail: {
								id: this.pathId,
								type: 3,
								pointId: this.pointId,
								entityUnderEdit: constants.objectType.TRENCH,
								disabilityConditions: "Z",
								origin: "CesiumView",
							},
						}),
					);
					break;
				case 6:
					// eslint-disable-next-line no-case-declarations
					const trenches = this.$store.state.objects.filter(
						(trench) => trench.id === this.pathId,
					);
					if (trenches.length > 0) {
						this.$store.dispatch("endEditMode");
						window["viewer"].enableTrenchEdit(false, trenches[0].id, () => {});
						trenches[0].edit = false;
						// window["viewer"].toggleGlobeVisibility(true);
					}
					window["viewer"].clearCommandManager();
					break;
				case 7:
					window["viewer"].undo();
					break;
				case 8:
					window["viewer"].redo();
					break;
				case 9:
					//openup dialog box
					console.log("clicked point", {
						id: this.pathId,
						type: 5,
						clickedPointIndex: this.pointId,
						totalPoints: numberOfPathPoints,
					});
					window.dispatchEvent(
						new CustomEvent("openPropertyDialog", {
							detail: {
								id: this.pathId,
								type: 5,
								clickedPointIndex: clickedIndex,
								totalPoints: numberOfPathPoints,
							},
						}),
					);
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>
