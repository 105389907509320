<template>
    <div>
        <v-dialog :scrim="false" v-model="showAddDataDialog" width="40%">
            <select-type @close="closeDialog()" @open-terrain-data="openTerrainData" />
        </v-dialog>
        <v-dialog v-model="showTerrainDataDialog" width="600px" persistent>
            <add-terrain-data ref="add_terrain_data" @upload="uploadTerrainData" @close="closeTerrainData"
                :allowedFileTypes="allowedFileTypes" :axis_show="axisShow"
                :is_show="showTerrainDataDialog" />
        </v-dialog>
    </div>
</template>

<script>
import SelectType from "../AddData/SelectType.vue";
import addTerrainData from "../AddData/addTerrainData.vue";
import axios from "axios";
import { mapMutations, mapActions } from "vuex";

export default {
	components: {
		SelectType,
		addTerrainData,
	},
	props: {
		modelValue: Boolean,
	},
	data() {
		return {
			type: "",
			showTerrainDataDialog: false,
			axisShow: false,
			allowedFileTypes: "",
		};
	},
	computed: {
		showAddDataDialog: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("update:modelValue", value);
			},
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		...mapActions(["get_point_cloud_list", "get_design_list"]),
		closeDialog() {
			this.$emit("close");
		},
		openTerrainData(type, allowedFileTypes, axisShow) {
			// 選択ダイアログを閉じる
			this.showAddDataDialog = false;
			// アップロードダイアログを開く
			this.type = type;
			this.allowedFileTypes = allowedFileTypes;
			this.axisShow = axisShow;
			this.showTerrainDataDialog = true;
		},
		closeTerrainData() {
			this.type = "";
			this.allowedFileTypes = "";
			this.axisShow = false;
			this.showTerrainDataDialog = false;
		},
		async uploadTerrainData(dialogData) {
			if (dialogData) {
				this.$store.dispatch("updateIsLoading", true);
				const fd = new FormData();
				fd.append("file", dialogData[0]);
				fd.append("name", dialogData[1]);
				fd.append("date", dialogData[2]);
				fd.append("time", dialogData[3] + ":00");
				if (this.axisShow) {
					fd.append("axis", dialogData[4]);
				}

				const extension = dialogData[0].name.substring(
					dialogData[0].name.lastIndexOf(".") + 1,
				);
				let apiKey = this.type;
				if (extension.toLowerCase() === "sim") {
					apiKey = "linearfile";
				}

				try {
					const res = await axios.post(
						`${import.meta.env.VITE_API_BASE}/${apiKey}/${this.$route.query.siteId}`,
						fd,
						{
							auth: this.$store.state.authObject,
						},
					);
					switch (this.type) {
						case "pointcloud": {
							await this.get_point_cloud_list(this.$route.query.siteId);
							break;
						}
						case "design": {
							await this.get_design_list(this.$route.query.siteId);
							break;
						}
						case "lineworks": {
							if (extension.toLowerCase() === "dxf") {
								const line = {
									data_type: "lineworks",
									line_type: "dxf",
									lineworks_id: res.data.lineworks_id,
									create_at: res.data.create_at,
									lineworks_name: res.data.lineworks_name,
									lineworks_path: res.data.lineworks_path,
									processing_status: res.data.processing_status,
									site_id: res.data.site_id,
								};
								this.$store.commit("add_design", line);
							} else {
								const line = res.data;
								line.data_type = "lineworks";
								this.$store.commit("add_design", line);
							}
							break;
						}
					}
				} catch (e) {
					if (typeof e.toJSON === "function" && e.toJSON().status == 504) {
						this.set_snackbar({
							text: `${dialogData[1]}\n${this.$t("entity_upload_wait_msg")}`,
							color: "rgba(255, 215, 0, 0.72)",
						});
					} else {
						this.set_snackbar({
							text: `${dialogData[1]} ${this.$t("UPLOAD")} ${this.$t("failed")}.`,
							color: "rgba(153, 0, 0, 0.72)",
						});
					}
				} finally {
					this.$store.dispatch("updateIsLoading", false);
					this.closeTerrainData();
				}

				// アップロードリクエストから1秒毎にデータリストをリフレッシュ
				// let count = 0;
				// const timeId = setInterval(async () => {
				//   count++;
				//   if (count === 10) {
				//     clearInterval(timeId);
				//   }
				//   try {
				//     if (entity == "pointcloud") {
				//       await this.get_point_cloud_list(this.$route.query.siteId)
				//     } else {
				//       await this.get_design_list(this.$route.query.siteId)
				//     }
				//   } catch (error) {
				//     console.error(error.message);
				//   }
				// },1000);
			} else {
				this.closeTerrainData();
			}
		},
	},
};
</script>
